import { makeAutoObservable, runInAction } from "mobx";
import { User } from "../sdk/catalog";
import { adminUserController } from "../config/sdk";

class Manager {
  individuals: User[] = [];
  drivers: User[] = [];
  admins: User[] = [];
  constructor() {
    makeAutoObservable(this);
  }

  loadItems() {
    adminUserController.adminUserControllerGetAdmins().then((payload) => {
      runInAction(() => {
        this.admins = payload.data;
      });
    });

    adminUserController.adminUserControllerGetDrivers().then((payload) => {
      runInAction(() => {
        this.drivers = payload.data;
      });
    });

    adminUserController.adminUserControllerGetUsers().then((payload) => {
      runInAction(() => {
        this.individuals = payload.data;
      });
    });
  }
}

export const usersManager = new Manager();
