import { Box, Button, Group, Text } from "@mantine/core";
import { Link1 } from "iconsax-react";
import { Link } from "react-router-dom";

export function VerticalKeyValuePair({
  label,
  value,
  textAlign,
}: {
  label: string;
  value: string;
  textAlign?: string;
}) {
  return (
    <Box
      //@ts-ignore
      sx={{}}
    >
      <Text sx={{ color: "gray" }}>{label}</Text>
      <Text sx={{}}>{value}</Text>
    </Box>
  );
}

export function VerticalKeyValuePairLinkValue({
  label,
  value,
  textAlign,
}: {
  label: string;
  value: string;
  textAlign?: string;
}) {
  return (
    <Box
      //@ts-ignore
      sx={{}}
    >
      <Text sx={{ color: "gray" }}>{label}</Text>
      <a href={value} target="_blank">
        <Button sx={{}} variant="default" size="sm" leftIcon={<Link1 />}>
          View
        </Button>
      </a>
    </Box>
  );
}

export function HorizontalKeyValuePair({
  label,
  value,
  textAlign,
}: {
  label: string;
  value: string;
  textAlign?: string;
}) {
  return (
    <Group>
      <Text sx={{}}>{label}</Text>
      <Text sx={{ fontWeight: 700 }}>{value}</Text>
    </Group>
  );
}
