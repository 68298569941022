import {
  Header,
  MediaQuery,
  Burger,
  useMantineTheme,
  Text,
  Box,
  Stack,
  Title,
  Group,
  Input,
  Avatar,
  Menu,
  Button,
  ActionIcon,
} from "@mantine/core";
import { SpotlightProvider, openSpotlight } from "@mantine/spotlight";
import {
  Brodcast,
  CardTick,
  DocumentCode,
  Link1,
  Logout,
  Message,
  Notification,
  Shop,
  ShoppingBag,
  ShoppingCart,
} from "iconsax-react";
import { Link } from "react-router-dom";
import Logo from "./logo.svg";
import { authManager } from "@store/account/auth";

const MainHeader: React.FC<{
  setOpened: (data: any) => void;
  opened: boolean;
}> = ({ opened, setOpened }) => {
  let theme = useMantineTheme();
  return (
    <Header
      height={60}
      sx={(theme) => {
        return {
          // borderBottom: "0px solid gray",
          zIndex: 200,
          background: theme.colors.dark[8],
          color: "whitesmoke",
        };
      }}
    >
      <div style={{ display: "flex", height: "100%", alignItems: "center" }}>
        <MediaQuery largerThan="sm" styles={{ display: "none" }}>
          <Stack align={"center"} justify="center" px="md">
            <Burger
              opened={opened}
              onClick={() => setOpened((o: any) => !o)}
              size="sm"
              color={theme.colors.gray[6]}
              mr="xl"
            />
          </Stack>
        </MediaQuery>
        <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
          <Group
            sx={(theme) => {
              return {
                height: "100%",
                width: "250px",
                display: "flex",
                alignContent: "center",
                alignItems: "center",
              };
            }}
            p="md"
          >
            <Title size={"md"}>Admin Panel</Title>
          </Group>
        </MediaQuery>
        <Box p="md" sx={{ flex: 1 }}>
          <Group
            align={"center"}
            spacing="xl"
            sx={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <Box></Box>
            <Box>
              <Group>
                <Input
                  radius={"xl"}
                  placeholder="Search Customer"
                  onClick={() => {
                    openSpotlight();
                  }}
                />
              
                  <ActionIcon onClick={()=>{
                    authManager.logout()
                  }}>
                    <Logout />
                  </ActionIcon>
             
                <a href={"https://ridealong.ng"}>
                  <ActionIcon>
                    <Link1 />
                  </ActionIcon>
                </a>

                <a href={"https://api.ridealong.ng/docs/catalog"}>
                  <ActionIcon>
                    <DocumentCode />
                  </ActionIcon>
                </a>


              </Group>
            </Box>
          </Group>
        </Box>
      </div>
    </Header>
  );
};


export default MainHeader;
