import { makeAutoObservable, runInAction } from "mobx";
import { bookingController, payoutController } from "../config/sdk";
import { Booking, Payout } from "../sdk/catalog";

class Manager {
  items: Booking[] = [];
  constructor() {
    makeAutoObservable(this);
  }

  loadItems() {
    bookingController.bookingControllerGetBookings().then((response: any) => {
      runInAction(() => {
        this.items = response.items;
      })
    })
  }
}

export const bookingsManager = new Manager();
