import {
  Avatar,
  Button,
  Grid,
  Paper,
  Stack,
  useMantineTheme,
} from "@mantine/core";
import { authManager } from "@store/account/auth";
import { usersManager } from "@store/users";

import { VerticalKeyValuePair } from "@ui/molecules/text";
import { MainStatsCard } from "@ui/organisms/dashboard-widgets";
import { TransactionStats } from "@ui/organisms/dashboard-widgets/chart";
import { SectionHeader } from "@ui/organisms/header-widgets/SectionHeader";
import { Archive, Card, Document, LockCircle, People, Unlock } from "iconsax-react";
import { observer } from "mobx-react";

export const DashboardScreen = () => {
  let theme = useMantineTheme();
  return (
    <Stack spacing={"xl"} justify="stretch" mt={"xl"}>
      <AccountOverview />

      <TransactionStats />

      <AccountOthers />

    </Stack>
  );
};

const AccountOverview = observer(() => {
  return (
    <Paper
      p={"18px"}
      sx={(theme) => {
        return { width: "100%" };
      }}
    >
      <Stack>
        <SectionHeader title="Account Activities" showBorder={false} />
        <Grid>
          <Grid.Col md={3}>
            <MainStatsCard
              label="Total Passengers"
              caption="at this moment"
              color="orange"
              icon={<Archive variant="Bold" color="gray" />}
              value={usersManager.individuals.length + ""}
            />
          </Grid.Col>
          <Grid.Col md={3}>
            <MainStatsCard
              label="Total Drivers"
              caption="at this moment"
              color="orange"
              icon={<People variant="Bold" color="gray" />}
              value={usersManager.drivers.length + ""}
            />
          </Grid.Col>
          <Grid.Col md={3}>
            <MainStatsCard
              label="Outstanding Payouts"
              caption="at this moment"
              color="orange"
              icon={<Unlock variant="Bold" color="gray" />}
              value={"0"}
            />
          </Grid.Col>
          <Grid.Col md={3}>
            <MainStatsCard
              label="Total Payouts "
              caption="at this moment"
              color="orange"
              icon={<Card variant="Bold" color="gray" />}
              value={"0"}
            />
          </Grid.Col>
        </Grid>
      </Stack>
    </Paper>
  );
});

const AccountOthers = observer(() => {
  let profile = authManager.user;
  return (
    <Paper
      p={"18px"}
      sx={(theme) => {
        return { width: "100%" };
      }}

    >
      <Stack>
        <Grid>
          <Grid.Col md={12}>
            <Stack>
              <SectionHeader
                title="Profile"
                showBorder={false}
                right={
                  <Button variant="outline" size="sm">
                    Edit
                  </Button>
                }
              />

              <Grid>
                <Grid.Col md={2}>
                  <Avatar
                    size={"xl"}
                    src={profile?.profile_photo}
                  />
                </Grid.Col>
                <Grid.Col md={5}>
                  <VerticalKeyValuePair
                    label="First Name"
                    value={profile?.first_name}
                  />
                  <VerticalKeyValuePair
                    label="Last Name"
                    value={profile?.last_name}
                  />
                  <VerticalKeyValuePair label="Email" value={profile?.email} />
                </Grid.Col>
                <Grid.Col md={5}>
                  <VerticalKeyValuePair
                    label="Phone"
                    value={profile?.phone ?? "N/A"}
                  />
                  <VerticalKeyValuePair label="Address" value="N/A" />
                  <VerticalKeyValuePair label="Status" value="Active" />
                </Grid.Col>
              </Grid>
            </Stack>
          </Grid.Col>
        </Grid>
      </Stack>
    </Paper>
  );
});
