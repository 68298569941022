import { makeAutoObservable, runInAction } from "mobx";
import { adminPayoutController, payoutController } from "../config/sdk";
import { Payout } from "../sdk/catalog";

class Manager {
  items: Payout[] = [];
  constructor() {
    makeAutoObservable(this);
  }

  loadItems() {
    adminPayoutController.adminPayoutControllerGetAllPayouts().then((payload) => {
      runInAction(() => {
        this.items = payload.data;
      });
    });
  }
}

export const payoutManager = new Manager();
