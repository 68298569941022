import {
  Box,
  createStyles,
  Grid,
  Paper,
  Stack,
  Text,
  Title,
  useMantineTheme,
} from "@mantine/core";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { formatCurrency, sumTransactions } from "../../../../utils";
import { MainChartCard } from "./Chart";
import { ReactChartComp } from "./react-charts-comp";
import { adminBookignController, adminTripController } from "../../../../config/sdk";

export const TransactionStats = observer(() => {
  let [tripStats, setTripStats] = useState();
  let [bookingStats, setBookingStats] = useState();

  function load() {
    adminTripController.adminTripControllerGetStats().then((response: any) => {
      console.log(response.data)
      setTripStats(response.data)
    })
    adminBookignController.adminBookingControllerGetStats().then((response: any) => {
      setBookingStats(response.data)
    })
  }

  useEffect(() => {
    load();
  }, [])
  return (
    <Stack spacing={"xl"}>
      <Grid align="stretch">
        <Grid.Col
          md={6}
          onClick={() => {

          }}
        >
          <Stack style={{ height: "100%" }} justify="space-between">
            <PageTab
              label="Awaiting trips"
              //@ts-ignore
              value={tripStats?.awaiting_trips_count ?? 0}

            />

            <PageTab
              label="Processing Trips"
              //@ts-ignore
              value={tripStats?.processing_trips_count ?? 0}

            />

            <PageTab
              label="Completed trips"
              //@ts-ignore
              value={tripStats?.completed_trips_count ?? 0}
            />
          </Stack>
        </Grid.Col>
        <Grid.Col
          md={6}
          onClick={() => {

          }}
        >
          <Stack>
            <PageTab
              label="Pending bookings"
              //@ts-ignore
              value={bookingStats?.awaiting_booking_count ?? 0}

            />

            <PageTab
              label="Active bookings"
              //@ts-ignore
              //@ts-ignore
              value={bookingStats?.processing_booking_count ?? 0}

            />

            <PageTab
              label="Completed bookings"
              //@ts-ignore
              value={bookingStats?.completed_count ?? 0}

            />
          </Stack>
        </Grid.Col>
      </Grid>

    </Stack>
  );
});

function PageTab({
  label,
  value,
}: {
  label: string;
  value: string;

}) {
  const theme = useMantineTheme();

  return (
    <Paper
      p="14px"
      sx={{
        borderRadius: 4,
      }}
      radius="xs"
      withBorder
    >
      <Text size={"sm"}>{label}</Text>
      <Title sx={{ fontSize: "28px" }}>{value}</Title>
    </Paper>
  );
}
