/* tslint:disable */
/* eslint-disable */
/**
 * Catalog Docs
 * Basic store catalog management features
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AttachAccountDto
 */
export interface AttachAccountDto {
    /**
     * 
     * @type {string}
     * @memberof AttachAccountDto
     */
    'business_name': string;
    /**
     * 
     * @type {string}
     * @memberof AttachAccountDto
     */
    'settlement_bank': string;
    /**
     * 
     * @type {string}
     * @memberof AttachAccountDto
     */
    'account_number': string;
    /**
     * 
     * @type {number}
     * @memberof AttachAccountDto
     */
    'percentage_charge': number;
    /**
     * 
     * @type {string}
     * @memberof AttachAccountDto
     */
    'primary_contact_email': string;
    /**
     * 
     * @type {string}
     * @memberof AttachAccountDto
     */
    'primary_contact_name': string;
    /**
     * 
     * @type {string}
     * @memberof AttachAccountDto
     */
    'primary_contact_phone': string;
}
/**
 * 
 * @export
 * @interface Booking
 */
export interface Booking {
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    'trip_id': string;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    'reservation_id': string;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    'passenger_id': string;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    'driver_id': string;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    'payment_status': string;
    /**
     * 
     * @type {object}
     * @memberof Booking
     */
    'seats': object;
    /**
     * 
     * @type {number}
     * @memberof Booking
     */
    'amount_payable': number;
    /**
     * 
     * @type {boolean}
     * @memberof Booking
     */
    'submitted_review': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Booking
     */
    'review_reminder_sent': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Booking
     */
    'cancellation_initiated': boolean;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    'refund_type': string;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    'refund_status': string;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    'driver_refund_status': string;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    'cancellation_initiated_by': string;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof Booking
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @interface CheckSeatsAvailabilityDto
 */
export interface CheckSeatsAvailabilityDto {
    /**
     * 
     * @type {object}
     * @memberof CheckSeatsAvailabilityDto
     */
    'seats': object;
}
/**
 * 
 * @export
 * @interface Driver
 */
export interface Driver {
    /**
     * 
     * @type {string}
     * @memberof Driver
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Driver
     */
    'notes': string;
    /**
     * 
     * @type {DriverKYC}
     * @memberof Driver
     */
    'driver_kyc': DriverKYC;
    /**
     * 
     * @type {string}
     * @memberof Driver
     */
    'driver_approval_status': DriverDriverApprovalStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Driver
     */
    'vehicle_approval_status': DriverVehicleApprovalStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Driver
     */
    'vehicle_photo': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Driver
     */
    'vehicle_photos': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Driver
     */
    'vehicle_company': string;
    /**
     * 
     * @type {string}
     * @memberof Driver
     */
    'vehicle_model': string;
    /**
     * 
     * @type {string}
     * @memberof Driver
     */
    'vehicle_sketch_handle': string;
    /**
     * 
     * @type {object}
     * @memberof Driver
     */
    'vehicle_sketch': object;
    /**
     * 
     * @type {string}
     * @memberof Driver
     */
    'vehicle_color': string;
    /**
     * 
     * @type {string}
     * @memberof Driver
     */
    'vehicle_year': string;
    /**
     * 
     * @type {string}
     * @memberof Driver
     */
    'vehicle_seats': string;
    /**
     * 
     * @type {string}
     * @memberof Driver
     */
    'vehicle_vin': string;
    /**
     * 
     * @type {string}
     * @memberof Driver
     */
    'vehicle_licence': string;
    /**
     * 
     * @type {string}
     * @memberof Driver
     */
    'certificate_of_road_worthiness': string;
    /**
     * 
     * @type {string}
     * @memberof Driver
     */
    'proof_of_ownership': string;
}

export const DriverDriverApprovalStatusEnum = {
    Pending: 'PENDING',
    Requested: 'REQUESTED',
    Approved: 'APPROVED',
    Disapproved: 'DISAPPROVED'
} as const;

export type DriverDriverApprovalStatusEnum = typeof DriverDriverApprovalStatusEnum[keyof typeof DriverDriverApprovalStatusEnum];
export const DriverVehicleApprovalStatusEnum = {
    Pending: 'PENDING',
    Requested: 'REQUESTED',
    Approved: 'APPROVED',
    Disapproved: 'DISAPPROVED'
} as const;

export type DriverVehicleApprovalStatusEnum = typeof DriverVehicleApprovalStatusEnum[keyof typeof DriverVehicleApprovalStatusEnum];

/**
 * 
 * @export
 * @interface DriverKYC
 */
export interface DriverKYC {
    /**
     * 
     * @type {string}
     * @memberof DriverKYC
     */
    'date_of_birth': string;
    /**
     * 
     * @type {string}
     * @memberof DriverKYC
     */
    'state': string;
    /**
     * 
     * @type {string}
     * @memberof DriverKYC
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof DriverKYC
     */
    'photo': string;
    /**
     * 
     * @type {string}
     * @memberof DriverKYC
     */
    'driver_licence_number': string;
    /**
     * 
     * @type {string}
     * @memberof DriverKYC
     */
    'driver_licence_front_page': string;
    /**
     * 
     * @type {string}
     * @memberof DriverKYC
     */
    'driver_licence_back_page': string;
    /**
     * 
     * @type {string}
     * @memberof DriverKYC
     */
    'police_character_report': string;
    /**
     * 
     * @type {string}
     * @memberof DriverKYC
     */
    'about': string;
    /**
     * 
     * @type {string}
     * @memberof DriverKYC
     */
    'guarantor1_fullname': string;
    /**
     * 
     * @type {string}
     * @memberof DriverKYC
     */
    'guarantor1_email': string;
    /**
     * 
     * @type {string}
     * @memberof DriverKYC
     */
    'guarantor1_phone': string;
    /**
     * 
     * @type {string}
     * @memberof DriverKYC
     */
    'guarantor1_government_issued_id': string;
    /**
     * 
     * @type {string}
     * @memberof DriverKYC
     */
    'guarantor2_fullname': string;
    /**
     * 
     * @type {string}
     * @memberof DriverKYC
     */
    'guarantor2_email': string;
    /**
     * 
     * @type {string}
     * @memberof DriverKYC
     */
    'guarantor2_phone': string;
    /**
     * 
     * @type {string}
     * @memberof DriverKYC
     */
    'guarantor2_government_issued_id': string;
}
/**
 * 
 * @export
 * @interface InitReservationPaymentDto
 */
export interface InitReservationPaymentDto {
    /**
     * 
     * @type {object}
     * @memberof InitReservationPaymentDto
     */
    'seats': object;
    /**
     * 
     * @type {string}
     * @memberof InitReservationPaymentDto
     */
    'reservation_id': string;
}
/**
 * 
 * @export
 * @interface Location
 */
export interface Location {
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    'state': string;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    'postal_code': string;
    /**
     * 
     * @type {object}
     * @memberof Location
     */
    'geometry': object;
}
/**
 * 
 * @export
 * @interface Payout
 */
export interface Payout {
    /**
     * 
     * @type {string}
     * @memberof Payout
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Payout
     */
    'payout_type': string;
    /**
     * 
     * @type {string}
     * @memberof Payout
     */
    'payment_status': string;
    /**
     * 
     * @type {string}
     * @memberof Payout
     */
    'status': string;
    /**
     * 
     * @type {object}
     * @memberof Payout
     */
    'paystack_transaction_ref': object;
    /**
     * 
     * @type {number}
     * @memberof Payout
     */
    'amount': number;
    /**
     * 
     * @type {string}
     * @memberof Payout
     */
    'user_id': string;
    /**
     * 
     * @type {string}
     * @memberof Payout
     */
    'notes': string;
    /**
     * 
     * @type {string}
     * @memberof Payout
     */
    'bookings': string;
    /**
     * 
     * @type {string}
     * @memberof Payout
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof Payout
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @interface PaystackTransferReceipient
 */
export interface PaystackTransferReceipient {
    /**
     * 
     * @type {boolean}
     * @memberof PaystackTransferReceipient
     */
    'active': boolean;
    /**
     * 
     * @type {string}
     * @memberof PaystackTransferReceipient
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof PaystackTransferReceipient
     */
    'currency': string;
    /**
     * 
     * @type {string}
     * @memberof PaystackTransferReceipient
     */
    'recipient_code': string;
    /**
     * 
     * @type {string}
     * @memberof PaystackTransferReceipient
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface PublishRequestDto
 */
export interface PublishRequestDto {
    /**
     * 
     * @type {RequestSpec}
     * @memberof PublishRequestDto
     */
    'request_spec': RequestSpec;
    /**
     * 
     * @type {Location}
     * @memberof PublishRequestDto
     */
    'start_location': Location;
    /**
     * 
     * @type {Location}
     * @memberof PublishRequestDto
     */
    'end_location': Location;
    /**
     * 
     * @type {string}
     * @memberof PublishRequestDto
     */
    'notes'?: string;
}
/**
 * 
 * @export
 * @interface PublishReservationDto
 */
export interface PublishReservationDto {
    /**
     * 
     * @type {string}
     * @memberof PublishReservationDto
     */
    'trip_id': string;
    /**
     * 
     * @type {string}
     * @memberof PublishReservationDto
     */
    'driver_id': string;
    /**
     * 
     * @type {object}
     * @memberof PublishReservationDto
     */
    'seats': object;
    /**
     * 
     * @type {number}
     * @memberof PublishReservationDto
     */
    'price_per_seat': number;
    /**
     * 
     * @type {string}
     * @memberof PublishReservationDto
     */
    'trip_starts_at': string;
}
/**
 * 
 * @export
 * @interface PublishReviewDto
 */
export interface PublishReviewDto {
    /**
     * 
     * @type {string}
     * @memberof PublishReviewDto
     */
    'user_id': string;
    /**
     * 
     * @type {string}
     * @memberof PublishReviewDto
     */
    'comment': string;
    /**
     * 
     * @type {string}
     * @memberof PublishReviewDto
     */
    'item_id': string;
    /**
     * 
     * @type {number}
     * @memberof PublishReviewDto
     */
    'rating': number;
}
/**
 * 
 * @export
 * @interface PublishTicketDto
 */
export interface PublishTicketDto {
    /**
     * 
     * @type {string}
     * @memberof PublishTicketDto
     */
    'subject': string;
    /**
     * 
     * @type {string}
     * @memberof PublishTicketDto
     */
    'issue': string;
}
/**
 * 
 * @export
 * @interface PublishTripDto
 */
export interface PublishTripDto {
    /**
     * 
     * @type {string}
     * @memberof PublishTripDto
     */
    'initiated_by': PublishTripDtoInitiatedByEnum;
    /**
     * 
     * @type {string}
     * @memberof PublishTripDto
     */
    'notes': string;
    /**
     * 
     * @type {TripSpec}
     * @memberof PublishTripDto
     */
    'trip_spec': TripSpec;
    /**
     * 
     * @type {Location}
     * @memberof PublishTripDto
     */
    'start_location': Location;
    /**
     * 
     * @type {Location}
     * @memberof PublishTripDto
     */
    'end_location': Location;
    /**
     * 
     * @type {object}
     * @memberof PublishTripDto
     */
    'seats': object;
    /**
     * 
     * @type {object}
     * @memberof PublishTripDto
     */
    'stops': object;
    /**
     * 
     * @type {string}
     * @memberof PublishTripDto
     */
    'trip_starts_at': string;
}

export const PublishTripDtoInitiatedByEnum = {
    Driver: 'DRIVER',
    Passenger: 'PASSENGER'
} as const;

export type PublishTripDtoInitiatedByEnum = typeof PublishTripDtoInitiatedByEnum[keyof typeof PublishTripDtoInitiatedByEnum];

/**
 * 
 * @export
 * @interface Request
 */
export interface Request {
    /**
     * 
     * @type {string}
     * @memberof Request
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Request
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof Request
     */
    'visibility': string;
    /**
     * 
     * @type {RequestSpec}
     * @memberof Request
     */
    'request_spec': RequestSpec;
    /**
     * 
     * @type {string}
     * @memberof Request
     */
    'from_district': string;
    /**
     * 
     * @type {string}
     * @memberof Request
     */
    'to_district': string;
    /**
     * 
     * @type {Location}
     * @memberof Request
     */
    'start_location': Location;
    /**
     * 
     * @type {Location}
     * @memberof Request
     */
    'end_location': Location;
    /**
     * 
     * @type {string}
     * @memberof Request
     */
    'notes': string;
    /**
     * 
     * @type {string}
     * @memberof Request
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof Request
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @interface RequestSpec
 */
export interface RequestSpec {
    /**
     * 
     * @type {number}
     * @memberof RequestSpec
     */
    'offer_amount': number;
    /**
     * 
     * @type {boolean}
     * @memberof RequestSpec
     */
    'offer_is_biddable': boolean;
    /**
     * 
     * @type {string}
     * @memberof RequestSpec
     */
    'importance': string;
    /**
     * 
     * @type {string}
     * @memberof RequestSpec
     */
    'starts_at': string;
}
/**
 * 
 * @export
 * @interface Reservation
 */
export interface Reservation {
    /**
     * 
     * @type {string}
     * @memberof Reservation
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Reservation
     */
    'publisher_id': string;
    /**
     * 
     * @type {string}
     * @memberof Reservation
     */
    'driver_id': string;
    /**
     * 
     * @type {string}
     * @memberof Reservation
     */
    'passenger_id': string;
    /**
     * 
     * @type {string}
     * @memberof Reservation
     */
    'trip_id': string;
    /**
     * 
     * @type {string}
     * @memberof Reservation
     */
    'trip_starts_at': string;
    /**
     * 
     * @type {number}
     * @memberof Reservation
     */
    'seat_count': number;
    /**
     * 
     * @type {object}
     * @memberof Reservation
     */
    'seats': object;
    /**
     * 
     * @type {number}
     * @memberof Reservation
     */
    'price_per_seat': number;
    /**
     * 
     * @type {string}
     * @memberof Reservation
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof Reservation
     */
    'payment_status': string;
    /**
     * 
     * @type {number}
     * @memberof Reservation
     */
    'amount_payable': number;
    /**
     * 
     * @type {string}
     * @memberof Reservation
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof Reservation
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @interface Review
 */
export interface Review {
    /**
     * 
     * @type {string}
     * @memberof Review
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Review
     */
    'publisher_id': string;
    /**
     * 
     * @type {string}
     * @memberof Review
     */
    'user_id': string;
    /**
     * 
     * @type {string}
     * @memberof Review
     */
    'item_id': string;
    /**
     * 
     * @type {string}
     * @memberof Review
     */
    'comment': string;
    /**
     * 
     * @type {number}
     * @memberof Review
     */
    'rating': number;
    /**
     * 
     * @type {string}
     * @memberof Review
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof Review
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @interface Ticket
 */
export interface Ticket {
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    'publisher_id': string;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    'status': TicketStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    'subject': string;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    'issue': string;
    /**
     * 
     * @type {number}
     * @memberof Ticket
     */
    'priority': number;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    'updated_at': string;
}

export const TicketStatusEnum = {
    Pending: 'PENDING',
    Processing: 'PROCESSING',
    Closed: 'CLOSED'
} as const;

export type TicketStatusEnum = typeof TicketStatusEnum[keyof typeof TicketStatusEnum];

/**
 * 
 * @export
 * @interface Trip
 */
export interface Trip {
    /**
     * 
     * @type {string}
     * @memberof Trip
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Trip
     */
    'initiated_by': string;
    /**
     * 
     * @type {string}
     * @memberof Trip
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof Trip
     */
    'from_district': string;
    /**
     * 
     * @type {string}
     * @memberof Trip
     */
    'to_district': string;
    /**
     * 
     * @type {TripSpec}
     * @memberof Trip
     */
    'trip_spec': TripSpec;
    /**
     * 
     * @type {Location}
     * @memberof Trip
     */
    'start_location': Location;
    /**
     * 
     * @type {object}
     * @memberof Trip
     */
    'stops': object;
    /**
     * 
     * @type {object}
     * @memberof Trip
     */
    'seats': object;
    /**
     * 
     * @type {Location}
     * @memberof Trip
     */
    'end_location': Location;
    /**
     * 
     * @type {string}
     * @memberof Trip
     */
    'trip_starts_at': string;
    /**
     * 
     * @type {string}
     * @memberof Trip
     */
    'notes': string;
    /**
     * 
     * @type {string}
     * @memberof Trip
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof Trip
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @interface TripSpec
 */
export interface TripSpec {
    /**
     * 
     * @type {number}
     * @memberof TripSpec
     */
    'price_per_seat': number;
    /**
     * 
     * @type {boolean}
     * @memberof TripSpec
     */
    'auto_accept_reservation': boolean;
    /**
     * 
     * @type {number}
     * @memberof TripSpec
     */
    'available_seats': number;
    /**
     * 
     * @type {boolean}
     * @memberof TripSpec
     */
    'allow_pets': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TripSpec
     */
    'allow_bag': boolean;
    /**
     * 
     * @type {string}
     * @memberof TripSpec
     */
    'bag_size': string;
    /**
     * 
     * @type {boolean}
     * @memberof TripSpec
     */
    'allow_smoking': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TripSpec
     */
    'allow_wifi': boolean;
}
/**
 * 
 * @export
 * @interface UpdateAccountStatus
 */
export interface UpdateAccountStatus {
    /**
     * 
     * @type {string}
     * @memberof UpdateAccountStatus
     */
    'account_status': UpdateAccountStatusAccountStatusEnum;
}

export const UpdateAccountStatusAccountStatusEnum = {
    Pending: 'PENDING',
    Active: 'ACTIVE',
    Disabled: 'DISABLED'
} as const;

export type UpdateAccountStatusAccountStatusEnum = typeof UpdateAccountStatusAccountStatusEnum[keyof typeof UpdateAccountStatusAccountStatusEnum];

/**
 * 
 * @export
 * @interface UpdateDriverAccountStatus
 */
export interface UpdateDriverAccountStatus {
    /**
     * 
     * @type {string}
     * @memberof UpdateDriverAccountStatus
     */
    'driver_account_status': UpdateDriverAccountStatusDriverAccountStatusEnum;
}

export const UpdateDriverAccountStatusDriverAccountStatusEnum = {
    Pending: 'PENDING',
    Active: 'ACTIVE',
    Disabled: 'DISABLED'
} as const;

export type UpdateDriverAccountStatusDriverAccountStatusEnum = typeof UpdateDriverAccountStatusDriverAccountStatusEnum[keyof typeof UpdateDriverAccountStatusDriverAccountStatusEnum];

/**
 * 
 * @export
 * @interface UpdateDriverKYCDto
 */
export interface UpdateDriverKYCDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateDriverKYCDto
     */
    'vehicle_photo': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateDriverKYCDto
     */
    'vehicle_photos': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpdateDriverKYCDto
     */
    'vehicle_company': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDriverKYCDto
     */
    'vehicle_model': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDriverKYCDto
     */
    'vehicle_sketch_handle': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDriverKYCDto
     */
    'vehicle_vin': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDriverKYCDto
     */
    'vehicle_year': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDriverKYCDto
     */
    'vehicle_color': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDriverKYCDto
     */
    'vehicle_licence': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDriverKYCDto
     */
    'certificate_of_road_worthiness': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDriverKYCDto
     */
    'vehicle_insurance': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDriverKYCDto
     */
    'proof_of_ownership': string;
    /**
     * 
     * @type {DriverKYC}
     * @memberof UpdateDriverKYCDto
     */
    'driver_kyc': DriverKYC;
}
/**
 * 
 * @export
 * @interface UpdatePayoutDto
 */
export interface UpdatePayoutDto {
    /**
     * 
     * @type {string}
     * @memberof UpdatePayoutDto
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePayoutDto
     */
    'payment_status': string;
}
/**
 * 
 * @export
 * @interface UpdateTicketStatusDto
 */
export interface UpdateTicketStatusDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateTicketStatusDto
     */
    'status': UpdateTicketStatusDtoStatusEnum;
}

export const UpdateTicketStatusDtoStatusEnum = {
    Pending: 'PENDING',
    Processing: 'PROCESSING',
    Closed: 'CLOSED'
} as const;

export type UpdateTicketStatusDtoStatusEnum = typeof UpdateTicketStatusDtoStatusEnum[keyof typeof UpdateTicketStatusDtoStatusEnum];

/**
 * 
 * @export
 * @interface UpdateTransferReceipient
 */
export interface UpdateTransferReceipient {
    /**
     * 
     * @type {string}
     * @memberof UpdateTransferReceipient
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTransferReceipient
     */
    'bank_code': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTransferReceipient
     */
    'account_number': string;
}
/**
 * 
 * @export
 * @interface UpdateUserDto
 */
export interface UpdateUserDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'bio': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'profile_photo': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'cover_photo': string;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'email': string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'email_activated': boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'metrics': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'last_name': string;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'driver_trips_count': number;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'passenger_trips_count': number;
    /**
     * 
     * @type {Location}
     * @memberof User
     */
    'location': Location;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'account_type': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'account_status': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'driver_account_status': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'passwordResetToken': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'passwordResetExpires': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'activationToken': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'activationExpires': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'profile_photo': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'cover_photo': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'bio': string;
    /**
     * 
     * @type {PaystackTransferReceipient}
     * @memberof User
     */
    'transfer_receipient': PaystackTransferReceipient;
    /**
     * 
     * @type {object}
     * @memberof User
     */
    'paystack_int': object;
}
/**
 * 
 * @export
 * @interface VehicleSketch
 */
export interface VehicleSketch {
    /**
     * 
     * @type {string}
     * @memberof VehicleSketch
     */
    'sketch_handle': string;
    /**
     * 
     * @type {string}
     * @memberof VehicleSketch
     */
    'sketch_image_url': string;
    /**
     * 
     * @type {number}
     * @memberof VehicleSketch
     */
    'seat_count': number;
}

/**
 * AdminBookingsControllerApi - axios parameter creator
 * @export
 */
export const AdminBookingsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [xAPIKEY] 
         * @param {string} [tripId] 
         * @param {string} [passengerId] 
         * @param {string} [driverId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBookingControllerGetFilteredBookings: async (xAPIKEY?: string, tripId?: string, passengerId?: string, driverId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/catalog/admin/bookings/get-filtered-bookings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (tripId !== undefined) {
                localVarQueryParameter['trip_id'] = tripId;
            }

            if (passengerId !== undefined) {
                localVarQueryParameter['passenger_id'] = passengerId;
            }

            if (driverId !== undefined) {
                localVarQueryParameter['driver_id'] = driverId;
            }

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBookingControllerGetStats: async (xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/catalog/admin/bookings/stats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminBookingsControllerApi - functional programming interface
 * @export
 */
export const AdminBookingsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminBookingsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [xAPIKEY] 
         * @param {string} [tripId] 
         * @param {string} [passengerId] 
         * @param {string} [driverId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminBookingControllerGetFilteredBookings(xAPIKEY?: string, tripId?: string, passengerId?: string, driverId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Booking>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminBookingControllerGetFilteredBookings(xAPIKEY, tripId, passengerId, driverId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminBookingControllerGetStats(xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminBookingControllerGetStats(xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminBookingsControllerApi - factory interface
 * @export
 */
export const AdminBookingsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminBookingsControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [xAPIKEY] 
         * @param {string} [tripId] 
         * @param {string} [passengerId] 
         * @param {string} [driverId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBookingControllerGetFilteredBookings(xAPIKEY?: string, tripId?: string, passengerId?: string, driverId?: string, options?: any): AxiosPromise<Array<Booking>> {
            return localVarFp.adminBookingControllerGetFilteredBookings(xAPIKEY, tripId, passengerId, driverId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminBookingControllerGetStats(xAPIKEY?: string, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.adminBookingControllerGetStats(xAPIKEY, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminBookingsControllerApi - object-oriented interface
 * @export
 * @class AdminBookingsControllerApi
 * @extends {BaseAPI}
 */
export class AdminBookingsControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} [xAPIKEY] 
     * @param {string} [tripId] 
     * @param {string} [passengerId] 
     * @param {string} [driverId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBookingsControllerApi
     */
    public adminBookingControllerGetFilteredBookings(xAPIKEY?: string, tripId?: string, passengerId?: string, driverId?: string, options?: AxiosRequestConfig) {
        return AdminBookingsControllerApiFp(this.configuration).adminBookingControllerGetFilteredBookings(xAPIKEY, tripId, passengerId, driverId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminBookingsControllerApi
     */
    public adminBookingControllerGetStats(xAPIKEY?: string, options?: AxiosRequestConfig) {
        return AdminBookingsControllerApiFp(this.configuration).adminBookingControllerGetStats(xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminDriverControllerApi - axios parameter creator
 * @export
 */
export const AdminDriverControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} driverId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDriverControllerApproveDriverProfile: async (driverId: string, xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'driverId' is not null or undefined
            assertParamExists('adminDriverControllerApproveDriverProfile', 'driverId', driverId)
            const localVarPath = `/api/catalog/admin/drivers/{driver_id}/approve-driver`
                .replace(`{${"driver_id"}}`, encodeURIComponent(String(driverId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} driverId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDriverControllerApproveVehicleProfile: async (driverId: string, xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'driverId' is not null or undefined
            assertParamExists('adminDriverControllerApproveVehicleProfile', 'driverId', driverId)
            const localVarPath = `/api/catalog/admin/drivers/{driver_id}/approve-vehicle`
                .replace(`{${"driver_id"}}`, encodeURIComponent(String(driverId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} driverId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDriverControllerDisapproveDriverProfile: async (driverId: string, xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'driverId' is not null or undefined
            assertParamExists('adminDriverControllerDisapproveDriverProfile', 'driverId', driverId)
            const localVarPath = `/api/catalog/admin/drivers/{driver_id}/disapprove-driver`
                .replace(`{${"driver_id"}}`, encodeURIComponent(String(driverId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} driverId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDriverControllerDisapproveVehicleProfile: async (driverId: string, xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'driverId' is not null or undefined
            assertParamExists('adminDriverControllerDisapproveVehicleProfile', 'driverId', driverId)
            const localVarPath = `/api/catalog/admin/drivers/{driver_id}/disapprove-vehicle`
                .replace(`{${"driver_id"}}`, encodeURIComponent(String(driverId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} driverId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDriverControllerGetDriverProfile: async (driverId: string, xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'driverId' is not null or undefined
            assertParamExists('adminDriverControllerGetDriverProfile', 'driverId', driverId)
            const localVarPath = `/api/catalog/admin/drivers/{driver_id}`
                .replace(`{${"driver_id"}}`, encodeURIComponent(String(driverId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDriverControllerGetOutStanding: async (xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/catalog/admin/drivers/get-outstanding`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminDriverControllerApi - functional programming interface
 * @export
 */
export const AdminDriverControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminDriverControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} driverId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDriverControllerApproveDriverProfile(driverId: string, xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Driver>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDriverControllerApproveDriverProfile(driverId, xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} driverId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDriverControllerApproveVehicleProfile(driverId: string, xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Driver>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDriverControllerApproveVehicleProfile(driverId, xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} driverId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDriverControllerDisapproveDriverProfile(driverId: string, xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Driver>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDriverControllerDisapproveDriverProfile(driverId, xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} driverId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDriverControllerDisapproveVehicleProfile(driverId: string, xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Driver>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDriverControllerDisapproveVehicleProfile(driverId, xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} driverId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDriverControllerGetDriverProfile(driverId: string, xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Driver>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDriverControllerGetDriverProfile(driverId, xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminDriverControllerGetOutStanding(xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Driver>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminDriverControllerGetOutStanding(xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminDriverControllerApi - factory interface
 * @export
 */
export const AdminDriverControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminDriverControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} driverId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDriverControllerApproveDriverProfile(driverId: string, xAPIKEY?: string, options?: any): AxiosPromise<Driver> {
            return localVarFp.adminDriverControllerApproveDriverProfile(driverId, xAPIKEY, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} driverId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDriverControllerApproveVehicleProfile(driverId: string, xAPIKEY?: string, options?: any): AxiosPromise<Driver> {
            return localVarFp.adminDriverControllerApproveVehicleProfile(driverId, xAPIKEY, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} driverId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDriverControllerDisapproveDriverProfile(driverId: string, xAPIKEY?: string, options?: any): AxiosPromise<Driver> {
            return localVarFp.adminDriverControllerDisapproveDriverProfile(driverId, xAPIKEY, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} driverId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDriverControllerDisapproveVehicleProfile(driverId: string, xAPIKEY?: string, options?: any): AxiosPromise<Driver> {
            return localVarFp.adminDriverControllerDisapproveVehicleProfile(driverId, xAPIKEY, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} driverId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDriverControllerGetDriverProfile(driverId: string, xAPIKEY?: string, options?: any): AxiosPromise<Array<Driver>> {
            return localVarFp.adminDriverControllerGetDriverProfile(driverId, xAPIKEY, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminDriverControllerGetOutStanding(xAPIKEY?: string, options?: any): AxiosPromise<Array<Driver>> {
            return localVarFp.adminDriverControllerGetOutStanding(xAPIKEY, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminDriverControllerApi - object-oriented interface
 * @export
 * @class AdminDriverControllerApi
 * @extends {BaseAPI}
 */
export class AdminDriverControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} driverId 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDriverControllerApi
     */
    public adminDriverControllerApproveDriverProfile(driverId: string, xAPIKEY?: string, options?: AxiosRequestConfig) {
        return AdminDriverControllerApiFp(this.configuration).adminDriverControllerApproveDriverProfile(driverId, xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} driverId 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDriverControllerApi
     */
    public adminDriverControllerApproveVehicleProfile(driverId: string, xAPIKEY?: string, options?: AxiosRequestConfig) {
        return AdminDriverControllerApiFp(this.configuration).adminDriverControllerApproveVehicleProfile(driverId, xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} driverId 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDriverControllerApi
     */
    public adminDriverControllerDisapproveDriverProfile(driverId: string, xAPIKEY?: string, options?: AxiosRequestConfig) {
        return AdminDriverControllerApiFp(this.configuration).adminDriverControllerDisapproveDriverProfile(driverId, xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} driverId 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDriverControllerApi
     */
    public adminDriverControllerDisapproveVehicleProfile(driverId: string, xAPIKEY?: string, options?: AxiosRequestConfig) {
        return AdminDriverControllerApiFp(this.configuration).adminDriverControllerDisapproveVehicleProfile(driverId, xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} driverId 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDriverControllerApi
     */
    public adminDriverControllerGetDriverProfile(driverId: string, xAPIKEY?: string, options?: AxiosRequestConfig) {
        return AdminDriverControllerApiFp(this.configuration).adminDriverControllerGetDriverProfile(driverId, xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminDriverControllerApi
     */
    public adminDriverControllerGetOutStanding(xAPIKEY?: string, options?: AxiosRequestConfig) {
        return AdminDriverControllerApiFp(this.configuration).adminDriverControllerGetOutStanding(xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminPayoutControllerApi - axios parameter creator
 * @export
 */
export const AdminPayoutControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPayoutControllerGetAllPayouts: async (xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/catalog/admin/payouts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} driverId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPayoutControllerGetAllUserPayouts: async (driverId: string, xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'driverId' is not null or undefined
            assertParamExists('adminPayoutControllerGetAllUserPayouts', 'driverId', driverId)
            const localVarPath = `/api/catalog/admin/payouts/driver-payouts/{driver_id}`
                .replace(`{${"driver_id"}}`, encodeURIComponent(String(driverId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPayoutControllerGetStats: async (xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/catalog/admin/payouts/stats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} driverId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPayoutControllerRequestDriverPayout: async (driverId: string, xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'driverId' is not null or undefined
            assertParamExists('adminPayoutControllerRequestDriverPayout', 'driverId', driverId)
            const localVarPath = `/api/catalog/admin/payouts/request-payout/{driver_id}`
                .replace(`{${"driver_id"}}`, encodeURIComponent(String(driverId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} payoutId 
         * @param {UpdatePayoutDto} updatePayoutDto 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPayoutControllerUpdatePayout: async (payoutId: string, updatePayoutDto: UpdatePayoutDto, xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'payoutId' is not null or undefined
            assertParamExists('adminPayoutControllerUpdatePayout', 'payoutId', payoutId)
            // verify required parameter 'updatePayoutDto' is not null or undefined
            assertParamExists('adminPayoutControllerUpdatePayout', 'updatePayoutDto', updatePayoutDto)
            const localVarPath = `/api/catalog/admin/payouts/{payout_id}`
                .replace(`{${"payout_id"}}`, encodeURIComponent(String(payoutId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePayoutDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminPayoutControllerApi - functional programming interface
 * @export
 */
export const AdminPayoutControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminPayoutControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminPayoutControllerGetAllPayouts(xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Payout>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminPayoutControllerGetAllPayouts(xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} driverId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminPayoutControllerGetAllUserPayouts(driverId: string, xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Payout>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminPayoutControllerGetAllUserPayouts(driverId, xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminPayoutControllerGetStats(xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminPayoutControllerGetStats(xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} driverId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminPayoutControllerRequestDriverPayout(driverId: string, xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Payout>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminPayoutControllerRequestDriverPayout(driverId, xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} payoutId 
         * @param {UpdatePayoutDto} updatePayoutDto 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminPayoutControllerUpdatePayout(payoutId: string, updatePayoutDto: UpdatePayoutDto, xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Payout>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminPayoutControllerUpdatePayout(payoutId, updatePayoutDto, xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminPayoutControllerApi - factory interface
 * @export
 */
export const AdminPayoutControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminPayoutControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPayoutControllerGetAllPayouts(xAPIKEY?: string, options?: any): AxiosPromise<Array<Payout>> {
            return localVarFp.adminPayoutControllerGetAllPayouts(xAPIKEY, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} driverId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPayoutControllerGetAllUserPayouts(driverId: string, xAPIKEY?: string, options?: any): AxiosPromise<Array<Payout>> {
            return localVarFp.adminPayoutControllerGetAllUserPayouts(driverId, xAPIKEY, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPayoutControllerGetStats(xAPIKEY?: string, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.adminPayoutControllerGetStats(xAPIKEY, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} driverId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPayoutControllerRequestDriverPayout(driverId: string, xAPIKEY?: string, options?: any): AxiosPromise<Array<Payout>> {
            return localVarFp.adminPayoutControllerRequestDriverPayout(driverId, xAPIKEY, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} payoutId 
         * @param {UpdatePayoutDto} updatePayoutDto 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminPayoutControllerUpdatePayout(payoutId: string, updatePayoutDto: UpdatePayoutDto, xAPIKEY?: string, options?: any): AxiosPromise<Payout> {
            return localVarFp.adminPayoutControllerUpdatePayout(payoutId, updatePayoutDto, xAPIKEY, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminPayoutControllerApi - object-oriented interface
 * @export
 * @class AdminPayoutControllerApi
 * @extends {BaseAPI}
 */
export class AdminPayoutControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPayoutControllerApi
     */
    public adminPayoutControllerGetAllPayouts(xAPIKEY?: string, options?: AxiosRequestConfig) {
        return AdminPayoutControllerApiFp(this.configuration).adminPayoutControllerGetAllPayouts(xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} driverId 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPayoutControllerApi
     */
    public adminPayoutControllerGetAllUserPayouts(driverId: string, xAPIKEY?: string, options?: AxiosRequestConfig) {
        return AdminPayoutControllerApiFp(this.configuration).adminPayoutControllerGetAllUserPayouts(driverId, xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPayoutControllerApi
     */
    public adminPayoutControllerGetStats(xAPIKEY?: string, options?: AxiosRequestConfig) {
        return AdminPayoutControllerApiFp(this.configuration).adminPayoutControllerGetStats(xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} driverId 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPayoutControllerApi
     */
    public adminPayoutControllerRequestDriverPayout(driverId: string, xAPIKEY?: string, options?: AxiosRequestConfig) {
        return AdminPayoutControllerApiFp(this.configuration).adminPayoutControllerRequestDriverPayout(driverId, xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} payoutId 
     * @param {UpdatePayoutDto} updatePayoutDto 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminPayoutControllerApi
     */
    public adminPayoutControllerUpdatePayout(payoutId: string, updatePayoutDto: UpdatePayoutDto, xAPIKEY?: string, options?: AxiosRequestConfig) {
        return AdminPayoutControllerApiFp(this.configuration).adminPayoutControllerUpdatePayout(payoutId, updatePayoutDto, xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminTicketControllerApi - axios parameter creator
 * @export
 */
export const AdminTicketControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTicketControllerGet: async (xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/catalog/admin/tickets/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} ticketId 
         * @param {UpdateTicketStatusDto} updateTicketStatusDto 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTicketControllerUpdateStatus: async (ticketId: string, updateTicketStatusDto: UpdateTicketStatusDto, xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticketId' is not null or undefined
            assertParamExists('adminTicketControllerUpdateStatus', 'ticketId', ticketId)
            // verify required parameter 'updateTicketStatusDto' is not null or undefined
            assertParamExists('adminTicketControllerUpdateStatus', 'updateTicketStatusDto', updateTicketStatusDto)
            const localVarPath = `/api/catalog/admin/tickets/{ticket_id}`
                .replace(`{${"ticket_id"}}`, encodeURIComponent(String(ticketId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTicketStatusDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminTicketControllerApi - functional programming interface
 * @export
 */
export const AdminTicketControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminTicketControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminTicketControllerGet(xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Ticket>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminTicketControllerGet(xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} ticketId 
         * @param {UpdateTicketStatusDto} updateTicketStatusDto 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminTicketControllerUpdateStatus(ticketId: string, updateTicketStatusDto: UpdateTicketStatusDto, xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Ticket>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminTicketControllerUpdateStatus(ticketId, updateTicketStatusDto, xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminTicketControllerApi - factory interface
 * @export
 */
export const AdminTicketControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminTicketControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTicketControllerGet(xAPIKEY?: string, options?: any): AxiosPromise<Array<Ticket>> {
            return localVarFp.adminTicketControllerGet(xAPIKEY, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} ticketId 
         * @param {UpdateTicketStatusDto} updateTicketStatusDto 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTicketControllerUpdateStatus(ticketId: string, updateTicketStatusDto: UpdateTicketStatusDto, xAPIKEY?: string, options?: any): AxiosPromise<Array<Ticket>> {
            return localVarFp.adminTicketControllerUpdateStatus(ticketId, updateTicketStatusDto, xAPIKEY, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminTicketControllerApi - object-oriented interface
 * @export
 * @class AdminTicketControllerApi
 * @extends {BaseAPI}
 */
export class AdminTicketControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTicketControllerApi
     */
    public adminTicketControllerGet(xAPIKEY?: string, options?: AxiosRequestConfig) {
        return AdminTicketControllerApiFp(this.configuration).adminTicketControllerGet(xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} ticketId 
     * @param {UpdateTicketStatusDto} updateTicketStatusDto 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTicketControllerApi
     */
    public adminTicketControllerUpdateStatus(ticketId: string, updateTicketStatusDto: UpdateTicketStatusDto, xAPIKEY?: string, options?: AxiosRequestConfig) {
        return AdminTicketControllerApiFp(this.configuration).adminTicketControllerUpdateStatus(ticketId, updateTicketStatusDto, xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminTripControllerApi - axios parameter creator
 * @export
 */
export const AdminTripControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} tripId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTripControllerDeleteItem: async (tripId: string, xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tripId' is not null or undefined
            assertParamExists('adminTripControllerDeleteItem', 'tripId', tripId)
            const localVarPath = `/api/catalog/admin/trips/{trip_id}`
                .replace(`{${"trip_id"}}`, encodeURIComponent(String(tripId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTripControllerGet: async (xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/catalog/admin/trips`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tripId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTripControllerGetById: async (tripId: string, xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tripId' is not null or undefined
            assertParamExists('adminTripControllerGetById', 'tripId', tripId)
            const localVarPath = `/api/catalog/admin/trips/{trip_id}`
                .replace(`{${"trip_id"}}`, encodeURIComponent(String(tripId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xAPIKEY] 
         * @param {string} [driverId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTripControllerGetFiltered: async (xAPIKEY?: string, driverId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/catalog/admin/trips/filtered`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (driverId !== undefined) {
                localVarQueryParameter['driver_id'] = driverId;
            }

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTripControllerGetStats: async (xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/catalog/admin/trips/stats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tripId 
         * @param {object} body 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTripControllerUpdateTrip: async (tripId: string, body: object, xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tripId' is not null or undefined
            assertParamExists('adminTripControllerUpdateTrip', 'tripId', tripId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('adminTripControllerUpdateTrip', 'body', body)
            const localVarPath = `/api/catalog/admin/trips/{trip_id}`
                .replace(`{${"trip_id"}}`, encodeURIComponent(String(tripId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminTripControllerApi - functional programming interface
 * @export
 */
export const AdminTripControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminTripControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} tripId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminTripControllerDeleteItem(tripId: string, xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Trip>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminTripControllerDeleteItem(tripId, xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminTripControllerGet(xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Trip>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminTripControllerGet(xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tripId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminTripControllerGetById(tripId: string, xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Trip>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminTripControllerGetById(tripId, xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xAPIKEY] 
         * @param {string} [driverId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminTripControllerGetFiltered(xAPIKEY?: string, driverId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Trip>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminTripControllerGetFiltered(xAPIKEY, driverId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminTripControllerGetStats(xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<object>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminTripControllerGetStats(xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tripId 
         * @param {object} body 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminTripControllerUpdateTrip(tripId: string, body: object, xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Trip>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminTripControllerUpdateTrip(tripId, body, xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminTripControllerApi - factory interface
 * @export
 */
export const AdminTripControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminTripControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} tripId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTripControllerDeleteItem(tripId: string, xAPIKEY?: string, options?: any): AxiosPromise<Trip> {
            return localVarFp.adminTripControllerDeleteItem(tripId, xAPIKEY, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTripControllerGet(xAPIKEY?: string, options?: any): AxiosPromise<Array<Trip>> {
            return localVarFp.adminTripControllerGet(xAPIKEY, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tripId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTripControllerGetById(tripId: string, xAPIKEY?: string, options?: any): AxiosPromise<Trip> {
            return localVarFp.adminTripControllerGetById(tripId, xAPIKEY, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xAPIKEY] 
         * @param {string} [driverId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTripControllerGetFiltered(xAPIKEY?: string, driverId?: string, options?: any): AxiosPromise<Array<Trip>> {
            return localVarFp.adminTripControllerGetFiltered(xAPIKEY, driverId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTripControllerGetStats(xAPIKEY?: string, options?: any): AxiosPromise<Array<object>> {
            return localVarFp.adminTripControllerGetStats(xAPIKEY, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tripId 
         * @param {object} body 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminTripControllerUpdateTrip(tripId: string, body: object, xAPIKEY?: string, options?: any): AxiosPromise<Trip> {
            return localVarFp.adminTripControllerUpdateTrip(tripId, body, xAPIKEY, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminTripControllerApi - object-oriented interface
 * @export
 * @class AdminTripControllerApi
 * @extends {BaseAPI}
 */
export class AdminTripControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} tripId 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTripControllerApi
     */
    public adminTripControllerDeleteItem(tripId: string, xAPIKEY?: string, options?: AxiosRequestConfig) {
        return AdminTripControllerApiFp(this.configuration).adminTripControllerDeleteItem(tripId, xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTripControllerApi
     */
    public adminTripControllerGet(xAPIKEY?: string, options?: AxiosRequestConfig) {
        return AdminTripControllerApiFp(this.configuration).adminTripControllerGet(xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tripId 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTripControllerApi
     */
    public adminTripControllerGetById(tripId: string, xAPIKEY?: string, options?: AxiosRequestConfig) {
        return AdminTripControllerApiFp(this.configuration).adminTripControllerGetById(tripId, xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xAPIKEY] 
     * @param {string} [driverId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTripControllerApi
     */
    public adminTripControllerGetFiltered(xAPIKEY?: string, driverId?: string, options?: AxiosRequestConfig) {
        return AdminTripControllerApiFp(this.configuration).adminTripControllerGetFiltered(xAPIKEY, driverId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTripControllerApi
     */
    public adminTripControllerGetStats(xAPIKEY?: string, options?: AxiosRequestConfig) {
        return AdminTripControllerApiFp(this.configuration).adminTripControllerGetStats(xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tripId 
     * @param {object} body 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminTripControllerApi
     */
    public adminTripControllerUpdateTrip(tripId: string, body: object, xAPIKEY?: string, options?: AxiosRequestConfig) {
        return AdminTripControllerApiFp(this.configuration).adminTripControllerUpdateTrip(tripId, body, xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AdminUserControllerApi - axios parameter creator
 * @export
 */
export const AdminUserControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserControllerGetAdmins: async (xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/catalog/admin/user/admins`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserControllerGetDrivers: async (xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/catalog/admin/user/drivers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserControllerGetUsers: async (xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/catalog/admin/user/individuals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateAccountStatus} updateAccountStatus 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserControllerMakeAdmin: async (id: string, updateAccountStatus: UpdateAccountStatus, xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminUserControllerMakeAdmin', 'id', id)
            // verify required parameter 'updateAccountStatus' is not null or undefined
            assertParamExists('adminUserControllerMakeAdmin', 'updateAccountStatus', updateAccountStatus)
            const localVarPath = `/api/catalog/admin/user/admin/account/{id}/promote-user`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAccountStatus, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserControllerRemoveAdminFlag: async (id: string, xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminUserControllerRemoveAdminFlag', 'id', id)
            const localVarPath = `/api/catalog/admin/user/admin/account/{id}/remove-admin`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateAccountStatus} updateAccountStatus 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserControllerUpdateAccountStatus: async (id: string, updateAccountStatus: UpdateAccountStatus, xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminUserControllerUpdateAccountStatus', 'id', id)
            // verify required parameter 'updateAccountStatus' is not null or undefined
            assertParamExists('adminUserControllerUpdateAccountStatus', 'updateAccountStatus', updateAccountStatus)
            const localVarPath = `/api/catalog/admin/user/admin/account/{id}/status`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAccountStatus, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserControllerUpdateAccountToAdmin: async (id: string, xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminUserControllerUpdateAccountToAdmin', 'id', id)
            const localVarPath = `/api/catalog/admin/user/admin/account/{id}/make-admin`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateDriverAccountStatus} updateDriverAccountStatus 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserControllerUpdateDriverAccountStatus: async (id: string, updateDriverAccountStatus: UpdateDriverAccountStatus, xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('adminUserControllerUpdateDriverAccountStatus', 'id', id)
            // verify required parameter 'updateDriverAccountStatus' is not null or undefined
            assertParamExists('adminUserControllerUpdateDriverAccountStatus', 'updateDriverAccountStatus', updateDriverAccountStatus)
            const localVarPath = `/api/catalog/admin/user/admin/account/{id}/driver-status`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDriverAccountStatus, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminUserControllerApi - functional programming interface
 * @export
 */
export const AdminUserControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminUserControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUserControllerGetAdmins(xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserControllerGetAdmins(xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUserControllerGetDrivers(xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserControllerGetDrivers(xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUserControllerGetUsers(xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserControllerGetUsers(xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateAccountStatus} updateAccountStatus 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUserControllerMakeAdmin(id: string, updateAccountStatus: UpdateAccountStatus, xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserControllerMakeAdmin(id, updateAccountStatus, xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUserControllerRemoveAdminFlag(id: string, xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserControllerRemoveAdminFlag(id, xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateAccountStatus} updateAccountStatus 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUserControllerUpdateAccountStatus(id: string, updateAccountStatus: UpdateAccountStatus, xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserControllerUpdateAccountStatus(id, updateAccountStatus, xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUserControllerUpdateAccountToAdmin(id: string, xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserControllerUpdateAccountToAdmin(id, xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateDriverAccountStatus} updateDriverAccountStatus 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminUserControllerUpdateDriverAccountStatus(id: string, updateDriverAccountStatus: UpdateDriverAccountStatus, xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminUserControllerUpdateDriverAccountStatus(id, updateDriverAccountStatus, xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminUserControllerApi - factory interface
 * @export
 */
export const AdminUserControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminUserControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserControllerGetAdmins(xAPIKEY?: string, options?: any): AxiosPromise<Array<User>> {
            return localVarFp.adminUserControllerGetAdmins(xAPIKEY, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserControllerGetDrivers(xAPIKEY?: string, options?: any): AxiosPromise<Array<User>> {
            return localVarFp.adminUserControllerGetDrivers(xAPIKEY, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserControllerGetUsers(xAPIKEY?: string, options?: any): AxiosPromise<Array<User>> {
            return localVarFp.adminUserControllerGetUsers(xAPIKEY, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateAccountStatus} updateAccountStatus 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserControllerMakeAdmin(id: string, updateAccountStatus: UpdateAccountStatus, xAPIKEY?: string, options?: any): AxiosPromise<User> {
            return localVarFp.adminUserControllerMakeAdmin(id, updateAccountStatus, xAPIKEY, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserControllerRemoveAdminFlag(id: string, xAPIKEY?: string, options?: any): AxiosPromise<User> {
            return localVarFp.adminUserControllerRemoveAdminFlag(id, xAPIKEY, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateAccountStatus} updateAccountStatus 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserControllerUpdateAccountStatus(id: string, updateAccountStatus: UpdateAccountStatus, xAPIKEY?: string, options?: any): AxiosPromise<User> {
            return localVarFp.adminUserControllerUpdateAccountStatus(id, updateAccountStatus, xAPIKEY, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserControllerUpdateAccountToAdmin(id: string, xAPIKEY?: string, options?: any): AxiosPromise<User> {
            return localVarFp.adminUserControllerUpdateAccountToAdmin(id, xAPIKEY, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateDriverAccountStatus} updateDriverAccountStatus 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminUserControllerUpdateDriverAccountStatus(id: string, updateDriverAccountStatus: UpdateDriverAccountStatus, xAPIKEY?: string, options?: any): AxiosPromise<User> {
            return localVarFp.adminUserControllerUpdateDriverAccountStatus(id, updateDriverAccountStatus, xAPIKEY, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminUserControllerApi - object-oriented interface
 * @export
 * @class AdminUserControllerApi
 * @extends {BaseAPI}
 */
export class AdminUserControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserControllerApi
     */
    public adminUserControllerGetAdmins(xAPIKEY?: string, options?: AxiosRequestConfig) {
        return AdminUserControllerApiFp(this.configuration).adminUserControllerGetAdmins(xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserControllerApi
     */
    public adminUserControllerGetDrivers(xAPIKEY?: string, options?: AxiosRequestConfig) {
        return AdminUserControllerApiFp(this.configuration).adminUserControllerGetDrivers(xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserControllerApi
     */
    public adminUserControllerGetUsers(xAPIKEY?: string, options?: AxiosRequestConfig) {
        return AdminUserControllerApiFp(this.configuration).adminUserControllerGetUsers(xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateAccountStatus} updateAccountStatus 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserControllerApi
     */
    public adminUserControllerMakeAdmin(id: string, updateAccountStatus: UpdateAccountStatus, xAPIKEY?: string, options?: AxiosRequestConfig) {
        return AdminUserControllerApiFp(this.configuration).adminUserControllerMakeAdmin(id, updateAccountStatus, xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserControllerApi
     */
    public adminUserControllerRemoveAdminFlag(id: string, xAPIKEY?: string, options?: AxiosRequestConfig) {
        return AdminUserControllerApiFp(this.configuration).adminUserControllerRemoveAdminFlag(id, xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateAccountStatus} updateAccountStatus 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserControllerApi
     */
    public adminUserControllerUpdateAccountStatus(id: string, updateAccountStatus: UpdateAccountStatus, xAPIKEY?: string, options?: AxiosRequestConfig) {
        return AdminUserControllerApiFp(this.configuration).adminUserControllerUpdateAccountStatus(id, updateAccountStatus, xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserControllerApi
     */
    public adminUserControllerUpdateAccountToAdmin(id: string, xAPIKEY?: string, options?: AxiosRequestConfig) {
        return AdminUserControllerApiFp(this.configuration).adminUserControllerUpdateAccountToAdmin(id, xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {UpdateDriverAccountStatus} updateDriverAccountStatus 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminUserControllerApi
     */
    public adminUserControllerUpdateDriverAccountStatus(id: string, updateDriverAccountStatus: UpdateDriverAccountStatus, xAPIKEY?: string, options?: AxiosRequestConfig) {
        return AdminUserControllerApiFp(this.configuration).adminUserControllerUpdateDriverAccountStatus(id, updateDriverAccountStatus, xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BookingsControllerApi - axios parameter creator
 * @export
 */
export const BookingsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} bookingId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingControllerCancelTrip: async (bookingId: string, xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookingId' is not null or undefined
            assertParamExists('bookingControllerCancelTrip', 'bookingId', bookingId)
            const localVarPath = `/api/catalog/bookings/{booking_id}/cancel`
                .replace(`{${"booking_id"}}`, encodeURIComponent(String(bookingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} bookingId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingControllerCompleted: async (bookingId: string, xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookingId' is not null or undefined
            assertParamExists('bookingControllerCompleted', 'bookingId', bookingId)
            const localVarPath = `/api/catalog/bookings/{booking_id}/completed`
                .replace(`{${"booking_id"}}`, encodeURIComponent(String(bookingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingControllerGetBookings: async (xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/catalog/bookings/get-bookings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} item 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingControllerGetById: async (item: string, xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'item' is not null or undefined
            assertParamExists('bookingControllerGetById', 'item', item)
            const localVarPath = `/api/catalog/bookings/{item}`
                .replace(`{${"item"}}`, encodeURIComponent(String(item)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xAPIKEY] 
         * @param {string} [tripId] 
         * @param {string} [passengerId] 
         * @param {string} [driverId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingControllerGetFilteredBookings: async (xAPIKEY?: string, tripId?: string, passengerId?: string, driverId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/catalog/bookings/get-filtered-bookings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (tripId !== undefined) {
                localVarQueryParameter['trip_id'] = tripId;
            }

            if (passengerId !== undefined) {
                localVarQueryParameter['passenger_id'] = passengerId;
            }

            if (driverId !== undefined) {
                localVarQueryParameter['driver_id'] = driverId;
            }

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} bookingId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingControllerOnboarded: async (bookingId: string, xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookingId' is not null or undefined
            assertParamExists('bookingControllerOnboarded', 'bookingId', bookingId)
            const localVarPath = `/api/catalog/bookings/{booking_id}/onboarded`
                .replace(`{${"booking_id"}}`, encodeURIComponent(String(bookingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BookingsControllerApi - functional programming interface
 * @export
 */
export const BookingsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BookingsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} bookingId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingControllerCancelTrip(bookingId: string, xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Booking>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingControllerCancelTrip(bookingId, xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} bookingId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingControllerCompleted(bookingId: string, xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Booking>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingControllerCompleted(bookingId, xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingControllerGetBookings(xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Booking>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingControllerGetBookings(xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} item 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingControllerGetById(item: string, xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Booking>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingControllerGetById(item, xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xAPIKEY] 
         * @param {string} [tripId] 
         * @param {string} [passengerId] 
         * @param {string} [driverId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingControllerGetFilteredBookings(xAPIKEY?: string, tripId?: string, passengerId?: string, driverId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Booking>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingControllerGetFilteredBookings(xAPIKEY, tripId, passengerId, driverId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} bookingId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bookingControllerOnboarded(bookingId: string, xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Booking>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bookingControllerOnboarded(bookingId, xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BookingsControllerApi - factory interface
 * @export
 */
export const BookingsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BookingsControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} bookingId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingControllerCancelTrip(bookingId: string, xAPIKEY?: string, options?: any): AxiosPromise<Booking> {
            return localVarFp.bookingControllerCancelTrip(bookingId, xAPIKEY, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} bookingId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingControllerCompleted(bookingId: string, xAPIKEY?: string, options?: any): AxiosPromise<Booking> {
            return localVarFp.bookingControllerCompleted(bookingId, xAPIKEY, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingControllerGetBookings(xAPIKEY?: string, options?: any): AxiosPromise<Array<Booking>> {
            return localVarFp.bookingControllerGetBookings(xAPIKEY, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} item 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingControllerGetById(item: string, xAPIKEY?: string, options?: any): AxiosPromise<Booking> {
            return localVarFp.bookingControllerGetById(item, xAPIKEY, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xAPIKEY] 
         * @param {string} [tripId] 
         * @param {string} [passengerId] 
         * @param {string} [driverId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingControllerGetFilteredBookings(xAPIKEY?: string, tripId?: string, passengerId?: string, driverId?: string, options?: any): AxiosPromise<Array<Booking>> {
            return localVarFp.bookingControllerGetFilteredBookings(xAPIKEY, tripId, passengerId, driverId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} bookingId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bookingControllerOnboarded(bookingId: string, xAPIKEY?: string, options?: any): AxiosPromise<Booking> {
            return localVarFp.bookingControllerOnboarded(bookingId, xAPIKEY, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BookingsControllerApi - object-oriented interface
 * @export
 * @class BookingsControllerApi
 * @extends {BaseAPI}
 */
export class BookingsControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} bookingId 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsControllerApi
     */
    public bookingControllerCancelTrip(bookingId: string, xAPIKEY?: string, options?: AxiosRequestConfig) {
        return BookingsControllerApiFp(this.configuration).bookingControllerCancelTrip(bookingId, xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} bookingId 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsControllerApi
     */
    public bookingControllerCompleted(bookingId: string, xAPIKEY?: string, options?: AxiosRequestConfig) {
        return BookingsControllerApiFp(this.configuration).bookingControllerCompleted(bookingId, xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsControllerApi
     */
    public bookingControllerGetBookings(xAPIKEY?: string, options?: AxiosRequestConfig) {
        return BookingsControllerApiFp(this.configuration).bookingControllerGetBookings(xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} item 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsControllerApi
     */
    public bookingControllerGetById(item: string, xAPIKEY?: string, options?: AxiosRequestConfig) {
        return BookingsControllerApiFp(this.configuration).bookingControllerGetById(item, xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xAPIKEY] 
     * @param {string} [tripId] 
     * @param {string} [passengerId] 
     * @param {string} [driverId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsControllerApi
     */
    public bookingControllerGetFilteredBookings(xAPIKEY?: string, tripId?: string, passengerId?: string, driverId?: string, options?: AxiosRequestConfig) {
        return BookingsControllerApiFp(this.configuration).bookingControllerGetFilteredBookings(xAPIKEY, tripId, passengerId, driverId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} bookingId 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookingsControllerApi
     */
    public bookingControllerOnboarded(bookingId: string, xAPIKEY?: string, options?: AxiosRequestConfig) {
        return BookingsControllerApiFp(this.configuration).bookingControllerOnboarded(bookingId, xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DriverControllerApi - axios parameter creator
 * @export
 */
export const DriverControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} driverId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverControllerGetDriverProfile: async (driverId: string, xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'driverId' is not null or undefined
            assertParamExists('driverControllerGetDriverProfile', 'driverId', driverId)
            const localVarPath = `/api/catalog/drivers/{driver_id}`
                .replace(`{${"driver_id"}}`, encodeURIComponent(String(driverId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateDriverKYCDto} updateDriverKYCDto 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverControllerUpdateDriverKYC: async (updateDriverKYCDto: UpdateDriverKYCDto, xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateDriverKYCDto' is not null or undefined
            assertParamExists('driverControllerUpdateDriverKYC', 'updateDriverKYCDto', updateDriverKYCDto)
            const localVarPath = `/api/catalog/drivers/update-kyc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDriverKYCDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DriverControllerApi - functional programming interface
 * @export
 */
export const DriverControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DriverControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} driverId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async driverControllerGetDriverProfile(driverId: string, xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Driver>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.driverControllerGetDriverProfile(driverId, xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateDriverKYCDto} updateDriverKYCDto 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async driverControllerUpdateDriverKYC(updateDriverKYCDto: UpdateDriverKYCDto, xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Driver>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.driverControllerUpdateDriverKYC(updateDriverKYCDto, xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DriverControllerApi - factory interface
 * @export
 */
export const DriverControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DriverControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} driverId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverControllerGetDriverProfile(driverId: string, xAPIKEY?: string, options?: any): AxiosPromise<Driver> {
            return localVarFp.driverControllerGetDriverProfile(driverId, xAPIKEY, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateDriverKYCDto} updateDriverKYCDto 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        driverControllerUpdateDriverKYC(updateDriverKYCDto: UpdateDriverKYCDto, xAPIKEY?: string, options?: any): AxiosPromise<Driver> {
            return localVarFp.driverControllerUpdateDriverKYC(updateDriverKYCDto, xAPIKEY, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DriverControllerApi - object-oriented interface
 * @export
 * @class DriverControllerApi
 * @extends {BaseAPI}
 */
export class DriverControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} driverId 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverControllerApi
     */
    public driverControllerGetDriverProfile(driverId: string, xAPIKEY?: string, options?: AxiosRequestConfig) {
        return DriverControllerApiFp(this.configuration).driverControllerGetDriverProfile(driverId, xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateDriverKYCDto} updateDriverKYCDto 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriverControllerApi
     */
    public driverControllerUpdateDriverKYC(updateDriverKYCDto: UpdateDriverKYCDto, xAPIKEY?: string, options?: AxiosRequestConfig) {
        return DriverControllerApiFp(this.configuration).driverControllerUpdateDriverKYC(updateDriverKYCDto, xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PayoutControllerApi - axios parameter creator
 * @export
 */
export const PayoutControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        payoutControllerGet: async (xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/catalog/payouts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        payoutControllerRequestPayout: async (xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/catalog/payouts/request-payout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PayoutControllerApi - functional programming interface
 * @export
 */
export const PayoutControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PayoutControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async payoutControllerGet(xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Payout>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.payoutControllerGet(xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async payoutControllerRequestPayout(xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Payout>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.payoutControllerRequestPayout(xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PayoutControllerApi - factory interface
 * @export
 */
export const PayoutControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PayoutControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        payoutControllerGet(xAPIKEY?: string, options?: any): AxiosPromise<Array<Payout>> {
            return localVarFp.payoutControllerGet(xAPIKEY, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        payoutControllerRequestPayout(xAPIKEY?: string, options?: any): AxiosPromise<Array<Payout>> {
            return localVarFp.payoutControllerRequestPayout(xAPIKEY, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PayoutControllerApi - object-oriented interface
 * @export
 * @class PayoutControllerApi
 * @extends {BaseAPI}
 */
export class PayoutControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PayoutControllerApi
     */
    public payoutControllerGet(xAPIKEY?: string, options?: AxiosRequestConfig) {
        return PayoutControllerApiFp(this.configuration).payoutControllerGet(xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PayoutControllerApi
     */
    public payoutControllerRequestPayout(xAPIKEY?: string, options?: AxiosRequestConfig) {
        return PayoutControllerApiFp(this.configuration).payoutControllerRequestPayout(xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProfileControllerApi - axios parameter creator
 * @export
 */
export const ProfileControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AttachAccountDto} attachAccountDto 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileControllerUpdatePayoutAccount: async (attachAccountDto: AttachAccountDto, xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'attachAccountDto' is not null or undefined
            assertParamExists('profileControllerUpdatePayoutAccount', 'attachAccountDto', attachAccountDto)
            const localVarPath = `/api/profile/update-payout-account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(attachAccountDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateUserDto} updateUserDto 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileControllerUpdateProfile: async (updateUserDto: UpdateUserDto, xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateUserDto' is not null or undefined
            assertParamExists('profileControllerUpdateProfile', 'updateUserDto', updateUserDto)
            const localVarPath = `/api/profile/update-profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateTransferReceipient} updateTransferReceipient 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileControllerUpdateTransactionReceipient: async (updateTransferReceipient: UpdateTransferReceipient, xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateTransferReceipient' is not null or undefined
            assertParamExists('profileControllerUpdateTransactionReceipient', 'updateTransferReceipient', updateTransferReceipient)
            const localVarPath = `/api/profile/update-transfer-receipient`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTransferReceipient, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProfileControllerApi - functional programming interface
 * @export
 */
export const ProfileControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProfileControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AttachAccountDto} attachAccountDto 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profileControllerUpdatePayoutAccount(attachAccountDto: AttachAccountDto, xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profileControllerUpdatePayoutAccount(attachAccountDto, xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateUserDto} updateUserDto 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profileControllerUpdateProfile(updateUserDto: UpdateUserDto, xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profileControllerUpdateProfile(updateUserDto, xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateTransferReceipient} updateTransferReceipient 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profileControllerUpdateTransactionReceipient(updateTransferReceipient: UpdateTransferReceipient, xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profileControllerUpdateTransactionReceipient(updateTransferReceipient, xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProfileControllerApi - factory interface
 * @export
 */
export const ProfileControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProfileControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {AttachAccountDto} attachAccountDto 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileControllerUpdatePayoutAccount(attachAccountDto: AttachAccountDto, xAPIKEY?: string, options?: any): AxiosPromise<User> {
            return localVarFp.profileControllerUpdatePayoutAccount(attachAccountDto, xAPIKEY, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateUserDto} updateUserDto 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileControllerUpdateProfile(updateUserDto: UpdateUserDto, xAPIKEY?: string, options?: any): AxiosPromise<User> {
            return localVarFp.profileControllerUpdateProfile(updateUserDto, xAPIKEY, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateTransferReceipient} updateTransferReceipient 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileControllerUpdateTransactionReceipient(updateTransferReceipient: UpdateTransferReceipient, xAPIKEY?: string, options?: any): AxiosPromise<User> {
            return localVarFp.profileControllerUpdateTransactionReceipient(updateTransferReceipient, xAPIKEY, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProfileControllerApi - object-oriented interface
 * @export
 * @class ProfileControllerApi
 * @extends {BaseAPI}
 */
export class ProfileControllerApi extends BaseAPI {
    /**
     * 
     * @param {AttachAccountDto} attachAccountDto 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileControllerApi
     */
    public profileControllerUpdatePayoutAccount(attachAccountDto: AttachAccountDto, xAPIKEY?: string, options?: AxiosRequestConfig) {
        return ProfileControllerApiFp(this.configuration).profileControllerUpdatePayoutAccount(attachAccountDto, xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateUserDto} updateUserDto 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileControllerApi
     */
    public profileControllerUpdateProfile(updateUserDto: UpdateUserDto, xAPIKEY?: string, options?: AxiosRequestConfig) {
        return ProfileControllerApiFp(this.configuration).profileControllerUpdateProfile(updateUserDto, xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateTransferReceipient} updateTransferReceipient 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileControllerApi
     */
    public profileControllerUpdateTransactionReceipient(updateTransferReceipient: UpdateTransferReceipient, xAPIKEY?: string, options?: AxiosRequestConfig) {
        return ProfileControllerApiFp(this.configuration).profileControllerUpdateTransactionReceipient(updateTransferReceipient, xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RequestsControllerApi - axios parameter creator
 * @export
 */
export const RequestsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} requestId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestControllerDelete: async (requestId: string, xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            assertParamExists('requestControllerDelete', 'requestId', requestId)
            const localVarPath = `/api/catalog/requests/{request_id}`
                .replace(`{${"request_id"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestControllerGet: async (xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/catalog/requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} locationDistrict 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestControllerGetOpenRequests: async (locationDistrict: string, xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationDistrict' is not null or undefined
            assertParamExists('requestControllerGetOpenRequests', 'locationDistrict', locationDistrict)
            const localVarPath = `/api/catalog/requests/open-requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (locationDistrict !== undefined) {
                localVarQueryParameter['location_district'] = locationDistrict;
            }

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PublishRequestDto} publishRequestDto 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestControllerPublish: async (publishRequestDto: PublishRequestDto, xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publishRequestDto' is not null or undefined
            assertParamExists('requestControllerPublish', 'publishRequestDto', publishRequestDto)
            const localVarPath = `/api/catalog/requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(publishRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} requestId 
         * @param {object} body 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestControllerUpdate: async (requestId: string, body: object, xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            assertParamExists('requestControllerUpdate', 'requestId', requestId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('requestControllerUpdate', 'body', body)
            const localVarPath = `/api/catalog/requests/{request_id}`
                .replace(`{${"request_id"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RequestsControllerApi - functional programming interface
 * @export
 */
export const RequestsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RequestsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} requestId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestControllerDelete(requestId: string, xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Request>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestControllerDelete(requestId, xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestControllerGet(xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Request>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestControllerGet(xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} locationDistrict 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestControllerGetOpenRequests(locationDistrict: string, xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Request>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestControllerGetOpenRequests(locationDistrict, xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PublishRequestDto} publishRequestDto 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestControllerPublish(publishRequestDto: PublishRequestDto, xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Request>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestControllerPublish(publishRequestDto, xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} requestId 
         * @param {object} body 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestControllerUpdate(requestId: string, body: object, xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Request>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestControllerUpdate(requestId, body, xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RequestsControllerApi - factory interface
 * @export
 */
export const RequestsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RequestsControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} requestId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestControllerDelete(requestId: string, xAPIKEY?: string, options?: any): AxiosPromise<Request> {
            return localVarFp.requestControllerDelete(requestId, xAPIKEY, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestControllerGet(xAPIKEY?: string, options?: any): AxiosPromise<Array<Request>> {
            return localVarFp.requestControllerGet(xAPIKEY, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} locationDistrict 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestControllerGetOpenRequests(locationDistrict: string, xAPIKEY?: string, options?: any): AxiosPromise<Array<Request>> {
            return localVarFp.requestControllerGetOpenRequests(locationDistrict, xAPIKEY, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PublishRequestDto} publishRequestDto 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestControllerPublish(publishRequestDto: PublishRequestDto, xAPIKEY?: string, options?: any): AxiosPromise<Request> {
            return localVarFp.requestControllerPublish(publishRequestDto, xAPIKEY, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} requestId 
         * @param {object} body 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestControllerUpdate(requestId: string, body: object, xAPIKEY?: string, options?: any): AxiosPromise<Request> {
            return localVarFp.requestControllerUpdate(requestId, body, xAPIKEY, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RequestsControllerApi - object-oriented interface
 * @export
 * @class RequestsControllerApi
 * @extends {BaseAPI}
 */
export class RequestsControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} requestId 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RequestsControllerApi
     */
    public requestControllerDelete(requestId: string, xAPIKEY?: string, options?: AxiosRequestConfig) {
        return RequestsControllerApiFp(this.configuration).requestControllerDelete(requestId, xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RequestsControllerApi
     */
    public requestControllerGet(xAPIKEY?: string, options?: AxiosRequestConfig) {
        return RequestsControllerApiFp(this.configuration).requestControllerGet(xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} locationDistrict 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RequestsControllerApi
     */
    public requestControllerGetOpenRequests(locationDistrict: string, xAPIKEY?: string, options?: AxiosRequestConfig) {
        return RequestsControllerApiFp(this.configuration).requestControllerGetOpenRequests(locationDistrict, xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PublishRequestDto} publishRequestDto 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RequestsControllerApi
     */
    public requestControllerPublish(publishRequestDto: PublishRequestDto, xAPIKEY?: string, options?: AxiosRequestConfig) {
        return RequestsControllerApiFp(this.configuration).requestControllerPublish(publishRequestDto, xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} requestId 
     * @param {object} body 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RequestsControllerApi
     */
    public requestControllerUpdate(requestId: string, body: object, xAPIKEY?: string, options?: AxiosRequestConfig) {
        return RequestsControllerApiFp(this.configuration).requestControllerUpdate(requestId, body, xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReservationControllerApi - axios parameter creator
 * @export
 */
export const ReservationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} reservationId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reservationControllerAcceptReservation: async (reservationId: string, xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reservationId' is not null or undefined
            assertParamExists('reservationControllerAcceptReservation', 'reservationId', reservationId)
            const localVarPath = `/api/catalog/reservation/{reservation_id}/accept-reservation`
                .replace(`{${"reservation_id"}}`, encodeURIComponent(String(reservationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reservationId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reservationControllerDeclineReservation: async (reservationId: string, xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reservationId' is not null or undefined
            assertParamExists('reservationControllerDeclineReservation', 'reservationId', reservationId)
            const localVarPath = `/api/catalog/reservation/{reservation_id}/decline-reservation`
                .replace(`{${"reservation_id"}}`, encodeURIComponent(String(reservationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reservationId 
         * @param {object} body 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reservationControllerDelete: async (reservationId: string, body: object, xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reservationId' is not null or undefined
            assertParamExists('reservationControllerDelete', 'reservationId', reservationId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('reservationControllerDelete', 'body', body)
            const localVarPath = `/api/catalog/reservation/{reservation_id}`
                .replace(`{${"reservation_id"}}`, encodeURIComponent(String(reservationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reservationControllerGet: async (xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/catalog/reservation/reservations-by-passenger`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tripId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reservationControllerGetByTrip: async (tripId: string, xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tripId' is not null or undefined
            assertParamExists('reservationControllerGetByTrip', 'tripId', tripId)
            const localVarPath = `/api/catalog/reservation/reservations-by-trip`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (tripId !== undefined) {
                localVarQueryParameter['trip_id'] = tripId;
            }

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PublishReservationDto} publishReservationDto 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reservationControllerPublish: async (publishReservationDto: PublishReservationDto, xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publishReservationDto' is not null or undefined
            assertParamExists('reservationControllerPublish', 'publishReservationDto', publishReservationDto)
            const localVarPath = `/api/catalog/reservation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(publishReservationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reservationId 
         * @param {object} body 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reservationControllerUpdate: async (reservationId: string, body: object, xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reservationId' is not null or undefined
            assertParamExists('reservationControllerUpdate', 'reservationId', reservationId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('reservationControllerUpdate', 'body', body)
            const localVarPath = `/api/catalog/reservation/{reservation_id}`
                .replace(`{${"reservation_id"}}`, encodeURIComponent(String(reservationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReservationControllerApi - functional programming interface
 * @export
 */
export const ReservationControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReservationControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} reservationId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reservationControllerAcceptReservation(reservationId: string, xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reservationControllerAcceptReservation(reservationId, xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} reservationId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reservationControllerDeclineReservation(reservationId: string, xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reservationControllerDeclineReservation(reservationId, xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} reservationId 
         * @param {object} body 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reservationControllerDelete(reservationId: string, body: object, xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Reservation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reservationControllerDelete(reservationId, body, xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reservationControllerGet(xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Reservation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reservationControllerGet(xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tripId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reservationControllerGetByTrip(tripId: string, xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Reservation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reservationControllerGetByTrip(tripId, xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PublishReservationDto} publishReservationDto 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reservationControllerPublish(publishReservationDto: PublishReservationDto, xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Reservation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reservationControllerPublish(publishReservationDto, xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} reservationId 
         * @param {object} body 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reservationControllerUpdate(reservationId: string, body: object, xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Reservation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reservationControllerUpdate(reservationId, body, xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReservationControllerApi - factory interface
 * @export
 */
export const ReservationControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReservationControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} reservationId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reservationControllerAcceptReservation(reservationId: string, xAPIKEY?: string, options?: any): AxiosPromise<void> {
            return localVarFp.reservationControllerAcceptReservation(reservationId, xAPIKEY, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} reservationId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reservationControllerDeclineReservation(reservationId: string, xAPIKEY?: string, options?: any): AxiosPromise<void> {
            return localVarFp.reservationControllerDeclineReservation(reservationId, xAPIKEY, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} reservationId 
         * @param {object} body 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reservationControllerDelete(reservationId: string, body: object, xAPIKEY?: string, options?: any): AxiosPromise<Reservation> {
            return localVarFp.reservationControllerDelete(reservationId, body, xAPIKEY, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reservationControllerGet(xAPIKEY?: string, options?: any): AxiosPromise<Array<Reservation>> {
            return localVarFp.reservationControllerGet(xAPIKEY, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tripId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reservationControllerGetByTrip(tripId: string, xAPIKEY?: string, options?: any): AxiosPromise<Array<Reservation>> {
            return localVarFp.reservationControllerGetByTrip(tripId, xAPIKEY, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PublishReservationDto} publishReservationDto 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reservationControllerPublish(publishReservationDto: PublishReservationDto, xAPIKEY?: string, options?: any): AxiosPromise<Reservation> {
            return localVarFp.reservationControllerPublish(publishReservationDto, xAPIKEY, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} reservationId 
         * @param {object} body 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reservationControllerUpdate(reservationId: string, body: object, xAPIKEY?: string, options?: any): AxiosPromise<Reservation> {
            return localVarFp.reservationControllerUpdate(reservationId, body, xAPIKEY, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReservationControllerApi - object-oriented interface
 * @export
 * @class ReservationControllerApi
 * @extends {BaseAPI}
 */
export class ReservationControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} reservationId 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationControllerApi
     */
    public reservationControllerAcceptReservation(reservationId: string, xAPIKEY?: string, options?: AxiosRequestConfig) {
        return ReservationControllerApiFp(this.configuration).reservationControllerAcceptReservation(reservationId, xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} reservationId 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationControllerApi
     */
    public reservationControllerDeclineReservation(reservationId: string, xAPIKEY?: string, options?: AxiosRequestConfig) {
        return ReservationControllerApiFp(this.configuration).reservationControllerDeclineReservation(reservationId, xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} reservationId 
     * @param {object} body 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationControllerApi
     */
    public reservationControllerDelete(reservationId: string, body: object, xAPIKEY?: string, options?: AxiosRequestConfig) {
        return ReservationControllerApiFp(this.configuration).reservationControllerDelete(reservationId, body, xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationControllerApi
     */
    public reservationControllerGet(xAPIKEY?: string, options?: AxiosRequestConfig) {
        return ReservationControllerApiFp(this.configuration).reservationControllerGet(xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tripId 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationControllerApi
     */
    public reservationControllerGetByTrip(tripId: string, xAPIKEY?: string, options?: AxiosRequestConfig) {
        return ReservationControllerApiFp(this.configuration).reservationControllerGetByTrip(tripId, xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PublishReservationDto} publishReservationDto 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationControllerApi
     */
    public reservationControllerPublish(publishReservationDto: PublishReservationDto, xAPIKEY?: string, options?: AxiosRequestConfig) {
        return ReservationControllerApiFp(this.configuration).reservationControllerPublish(publishReservationDto, xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} reservationId 
     * @param {object} body 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReservationControllerApi
     */
    public reservationControllerUpdate(reservationId: string, body: object, xAPIKEY?: string, options?: AxiosRequestConfig) {
        return ReservationControllerApiFp(this.configuration).reservationControllerUpdate(reservationId, body, xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReviewControllerApi - axios parameter creator
 * @export
 */
export const ReviewControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} itemId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewControllerCanReviewItem: async (itemId: string, xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('reviewControllerCanReviewItem', 'itemId', itemId)
            const localVarPath = `/api/catalog/reviews/can-review-item`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (itemId !== undefined) {
                localVarQueryParameter['item_id'] = itemId;
            }

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PublishReviewDto} publishReviewDto 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewControllerCreateReview: async (publishReviewDto: PublishReviewDto, xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publishReviewDto' is not null or undefined
            assertParamExists('reviewControllerCreateReview', 'publishReviewDto', publishReviewDto)
            const localVarPath = `/api/catalog/reviews`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(publishReviewDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reviewId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewControllerDeleteReview: async (reviewId: string, xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reviewId' is not null or undefined
            assertParamExists('reviewControllerDeleteReview', 'reviewId', reviewId)
            const localVarPath = `/api/catalog/reviews/{review_id}`
                .replace(`{${"review_id"}}`, encodeURIComponent(String(reviewId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewControllerGet: async (userId: string, xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('reviewControllerGet', 'userId', userId)
            const localVarPath = `/api/catalog/reviews/user-reviews`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['user_id'] = userId;
            }

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} itemId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewControllerGetItemReviews: async (itemId: string, xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('reviewControllerGetItemReviews', 'itemId', itemId)
            const localVarPath = `/api/catalog/reviews/item-reviews`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (itemId !== undefined) {
                localVarQueryParameter['item_id'] = itemId;
            }

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewControllerGetMyReviews: async (xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/catalog/reviews/my-reviews`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReviewControllerApi - functional programming interface
 * @export
 */
export const ReviewControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReviewControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} itemId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reviewControllerCanReviewItem(itemId: string, xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Review>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reviewControllerCanReviewItem(itemId, xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PublishReviewDto} publishReviewDto 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reviewControllerCreateReview(publishReviewDto: PublishReviewDto, xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Review>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reviewControllerCreateReview(publishReviewDto, xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} reviewId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reviewControllerDeleteReview(reviewId: string, xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Review>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reviewControllerDeleteReview(reviewId, xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reviewControllerGet(userId: string, xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Review>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reviewControllerGet(userId, xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} itemId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reviewControllerGetItemReviews(itemId: string, xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Review>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reviewControllerGetItemReviews(itemId, xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reviewControllerGetMyReviews(xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Review>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reviewControllerGetMyReviews(xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReviewControllerApi - factory interface
 * @export
 */
export const ReviewControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReviewControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} itemId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewControllerCanReviewItem(itemId: string, xAPIKEY?: string, options?: any): AxiosPromise<Array<Review>> {
            return localVarFp.reviewControllerCanReviewItem(itemId, xAPIKEY, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PublishReviewDto} publishReviewDto 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewControllerCreateReview(publishReviewDto: PublishReviewDto, xAPIKEY?: string, options?: any): AxiosPromise<Array<Review>> {
            return localVarFp.reviewControllerCreateReview(publishReviewDto, xAPIKEY, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} reviewId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewControllerDeleteReview(reviewId: string, xAPIKEY?: string, options?: any): AxiosPromise<Array<Review>> {
            return localVarFp.reviewControllerDeleteReview(reviewId, xAPIKEY, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewControllerGet(userId: string, xAPIKEY?: string, options?: any): AxiosPromise<Array<Review>> {
            return localVarFp.reviewControllerGet(userId, xAPIKEY, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} itemId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewControllerGetItemReviews(itemId: string, xAPIKEY?: string, options?: any): AxiosPromise<Array<Review>> {
            return localVarFp.reviewControllerGetItemReviews(itemId, xAPIKEY, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewControllerGetMyReviews(xAPIKEY?: string, options?: any): AxiosPromise<Array<Review>> {
            return localVarFp.reviewControllerGetMyReviews(xAPIKEY, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReviewControllerApi - object-oriented interface
 * @export
 * @class ReviewControllerApi
 * @extends {BaseAPI}
 */
export class ReviewControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} itemId 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewControllerApi
     */
    public reviewControllerCanReviewItem(itemId: string, xAPIKEY?: string, options?: AxiosRequestConfig) {
        return ReviewControllerApiFp(this.configuration).reviewControllerCanReviewItem(itemId, xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PublishReviewDto} publishReviewDto 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewControllerApi
     */
    public reviewControllerCreateReview(publishReviewDto: PublishReviewDto, xAPIKEY?: string, options?: AxiosRequestConfig) {
        return ReviewControllerApiFp(this.configuration).reviewControllerCreateReview(publishReviewDto, xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} reviewId 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewControllerApi
     */
    public reviewControllerDeleteReview(reviewId: string, xAPIKEY?: string, options?: AxiosRequestConfig) {
        return ReviewControllerApiFp(this.configuration).reviewControllerDeleteReview(reviewId, xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewControllerApi
     */
    public reviewControllerGet(userId: string, xAPIKEY?: string, options?: AxiosRequestConfig) {
        return ReviewControllerApiFp(this.configuration).reviewControllerGet(userId, xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} itemId 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewControllerApi
     */
    public reviewControllerGetItemReviews(itemId: string, xAPIKEY?: string, options?: AxiosRequestConfig) {
        return ReviewControllerApiFp(this.configuration).reviewControllerGetItemReviews(itemId, xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReviewControllerApi
     */
    public reviewControllerGetMyReviews(xAPIKEY?: string, options?: AxiosRequestConfig) {
        return ReviewControllerApiFp(this.configuration).reviewControllerGetMyReviews(xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SiteTripControllerApi - axios parameter creator
 * @export
 */
export const SiteTripControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} fromDistrict 
         * @param {string} toDistrict 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteTripControllerFindTrips: async (fromDistrict: string, toDistrict: string, xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fromDistrict' is not null or undefined
            assertParamExists('siteTripControllerFindTrips', 'fromDistrict', fromDistrict)
            // verify required parameter 'toDistrict' is not null or undefined
            assertParamExists('siteTripControllerFindTrips', 'toDistrict', toDistrict)
            const localVarPath = `/api/site/trips/find-trips`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fromDistrict !== undefined) {
                localVarQueryParameter['from_district'] = fromDistrict;
            }

            if (toDistrict !== undefined) {
                localVarQueryParameter['to_district'] = toDistrict;
            }

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteTripControllerGetAllTrips: async (xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/site/trips`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tripId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteTripControllerGetById: async (tripId: string, xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tripId' is not null or undefined
            assertParamExists('siteTripControllerGetById', 'tripId', tripId)
            const localVarPath = `/api/site/trips/{trip_id}`
                .replace(`{${"trip_id"}}`, encodeURIComponent(String(tripId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SiteTripControllerApi - functional programming interface
 * @export
 */
export const SiteTripControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SiteTripControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} fromDistrict 
         * @param {string} toDistrict 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async siteTripControllerFindTrips(fromDistrict: string, toDistrict: string, xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Trip>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.siteTripControllerFindTrips(fromDistrict, toDistrict, xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async siteTripControllerGetAllTrips(xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Trip>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.siteTripControllerGetAllTrips(xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tripId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async siteTripControllerGetById(tripId: string, xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Trip>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.siteTripControllerGetById(tripId, xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SiteTripControllerApi - factory interface
 * @export
 */
export const SiteTripControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SiteTripControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} fromDistrict 
         * @param {string} toDistrict 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteTripControllerFindTrips(fromDistrict: string, toDistrict: string, xAPIKEY?: string, options?: any): AxiosPromise<Array<Trip>> {
            return localVarFp.siteTripControllerFindTrips(fromDistrict, toDistrict, xAPIKEY, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteTripControllerGetAllTrips(xAPIKEY?: string, options?: any): AxiosPromise<Array<Trip>> {
            return localVarFp.siteTripControllerGetAllTrips(xAPIKEY, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tripId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        siteTripControllerGetById(tripId: string, xAPIKEY?: string, options?: any): AxiosPromise<Trip> {
            return localVarFp.siteTripControllerGetById(tripId, xAPIKEY, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SiteTripControllerApi - object-oriented interface
 * @export
 * @class SiteTripControllerApi
 * @extends {BaseAPI}
 */
export class SiteTripControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} fromDistrict 
     * @param {string} toDistrict 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteTripControllerApi
     */
    public siteTripControllerFindTrips(fromDistrict: string, toDistrict: string, xAPIKEY?: string, options?: AxiosRequestConfig) {
        return SiteTripControllerApiFp(this.configuration).siteTripControllerFindTrips(fromDistrict, toDistrict, xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteTripControllerApi
     */
    public siteTripControllerGetAllTrips(xAPIKEY?: string, options?: AxiosRequestConfig) {
        return SiteTripControllerApiFp(this.configuration).siteTripControllerGetAllTrips(xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tripId 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SiteTripControllerApi
     */
    public siteTripControllerGetById(tripId: string, xAPIKEY?: string, options?: AxiosRequestConfig) {
        return SiteTripControllerApiFp(this.configuration).siteTripControllerGetById(tripId, xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TicketControllerApi - axios parameter creator
 * @export
 */
export const TicketControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PublishTicketDto} publishTicketDto 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ticketControllerCreateTicket: async (publishTicketDto: PublishTicketDto, xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publishTicketDto' is not null or undefined
            assertParamExists('ticketControllerCreateTicket', 'publishTicketDto', publishTicketDto)
            const localVarPath = `/api/catalog/tickets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(publishTicketDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} ticketId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ticketControllerDeleteTicket: async (ticketId: string, xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticketId' is not null or undefined
            assertParamExists('ticketControllerDeleteTicket', 'ticketId', ticketId)
            const localVarPath = `/api/catalog/tickets/{ticket_id}`
                .replace(`{${"ticket_id"}}`, encodeURIComponent(String(ticketId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ticketControllerGet: async (xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/catalog/tickets/user-tickets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TicketControllerApi - functional programming interface
 * @export
 */
export const TicketControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TicketControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {PublishTicketDto} publishTicketDto 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ticketControllerCreateTicket(publishTicketDto: PublishTicketDto, xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Ticket>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ticketControllerCreateTicket(publishTicketDto, xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} ticketId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ticketControllerDeleteTicket(ticketId: string, xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Ticket>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ticketControllerDeleteTicket(ticketId, xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ticketControllerGet(xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Ticket>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ticketControllerGet(xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TicketControllerApi - factory interface
 * @export
 */
export const TicketControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TicketControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {PublishTicketDto} publishTicketDto 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ticketControllerCreateTicket(publishTicketDto: PublishTicketDto, xAPIKEY?: string, options?: any): AxiosPromise<Array<Ticket>> {
            return localVarFp.ticketControllerCreateTicket(publishTicketDto, xAPIKEY, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} ticketId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ticketControllerDeleteTicket(ticketId: string, xAPIKEY?: string, options?: any): AxiosPromise<Array<Ticket>> {
            return localVarFp.ticketControllerDeleteTicket(ticketId, xAPIKEY, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ticketControllerGet(xAPIKEY?: string, options?: any): AxiosPromise<Array<Ticket>> {
            return localVarFp.ticketControllerGet(xAPIKEY, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TicketControllerApi - object-oriented interface
 * @export
 * @class TicketControllerApi
 * @extends {BaseAPI}
 */
export class TicketControllerApi extends BaseAPI {
    /**
     * 
     * @param {PublishTicketDto} publishTicketDto 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketControllerApi
     */
    public ticketControllerCreateTicket(publishTicketDto: PublishTicketDto, xAPIKEY?: string, options?: AxiosRequestConfig) {
        return TicketControllerApiFp(this.configuration).ticketControllerCreateTicket(publishTicketDto, xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} ticketId 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketControllerApi
     */
    public ticketControllerDeleteTicket(ticketId: string, xAPIKEY?: string, options?: AxiosRequestConfig) {
        return TicketControllerApiFp(this.configuration).ticketControllerDeleteTicket(ticketId, xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketControllerApi
     */
    public ticketControllerGet(xAPIKEY?: string, options?: AxiosRequestConfig) {
        return TicketControllerApiFp(this.configuration).ticketControllerGet(xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TripControllerApi - axios parameter creator
 * @export
 */
export const TripControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} tripId 
         * @param {CheckSeatsAvailabilityDto} checkSeatsAvailabilityDto 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tripControllerCanAcceptReservation: async (tripId: string, checkSeatsAvailabilityDto: CheckSeatsAvailabilityDto, xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tripId' is not null or undefined
            assertParamExists('tripControllerCanAcceptReservation', 'tripId', tripId)
            // verify required parameter 'checkSeatsAvailabilityDto' is not null or undefined
            assertParamExists('tripControllerCanAcceptReservation', 'checkSeatsAvailabilityDto', checkSeatsAvailabilityDto)
            const localVarPath = `/api/catalog/trips/{trip_id}/check-seat-availability`
                .replace(`{${"trip_id"}}`, encodeURIComponent(String(tripId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(checkSeatsAvailabilityDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tripId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tripControllerCancelTrip: async (tripId: string, xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tripId' is not null or undefined
            assertParamExists('tripControllerCancelTrip', 'tripId', tripId)
            const localVarPath = `/api/catalog/trips/{trip_id}/cancel-trip`
                .replace(`{${"trip_id"}}`, encodeURIComponent(String(tripId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tripId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tripControllerDeleteItem: async (tripId: string, xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tripId' is not null or undefined
            assertParamExists('tripControllerDeleteItem', 'tripId', tripId)
            const localVarPath = `/api/catalog/trips/{trip_id}`
                .replace(`{${"trip_id"}}`, encodeURIComponent(String(tripId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tripId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tripControllerEndTrip: async (tripId: string, xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tripId' is not null or undefined
            assertParamExists('tripControllerEndTrip', 'tripId', tripId)
            const localVarPath = `/api/catalog/trips/{trip_id}/end-trip`
                .replace(`{${"trip_id"}}`, encodeURIComponent(String(tripId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tripControllerGet: async (xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/catalog/trips/driver-trips`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tripId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tripControllerGetById: async (tripId: string, xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tripId' is not null or undefined
            assertParamExists('tripControllerGetById', 'tripId', tripId)
            const localVarPath = `/api/catalog/trips/{trip_id}`
                .replace(`{${"trip_id"}}`, encodeURIComponent(String(tripId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tripId 
         * @param {InitReservationPaymentDto} initReservationPaymentDto 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tripControllerInitTripReservationPayment: async (tripId: string, initReservationPaymentDto: InitReservationPaymentDto, xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tripId' is not null or undefined
            assertParamExists('tripControllerInitTripReservationPayment', 'tripId', tripId)
            // verify required parameter 'initReservationPaymentDto' is not null or undefined
            assertParamExists('tripControllerInitTripReservationPayment', 'initReservationPaymentDto', initReservationPaymentDto)
            const localVarPath = `/api/catalog/trips/{trip_id}/init-reservation-payment`
                .replace(`{${"trip_id"}}`, encodeURIComponent(String(tripId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(initReservationPaymentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PublishTripDto} publishTripDto 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tripControllerPublish: async (publishTripDto: PublishTripDto, xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publishTripDto' is not null or undefined
            assertParamExists('tripControllerPublish', 'publishTripDto', publishTripDto)
            const localVarPath = `/api/catalog/trips`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(publishTripDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tripId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tripControllerStartTrip: async (tripId: string, xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tripId' is not null or undefined
            assertParamExists('tripControllerStartTrip', 'tripId', tripId)
            const localVarPath = `/api/catalog/trips/{trip_id}/start-trip`
                .replace(`{${"trip_id"}}`, encodeURIComponent(String(tripId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tripId 
         * @param {object} body 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tripControllerUpdate: async (tripId: string, body: object, xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tripId' is not null or undefined
            assertParamExists('tripControllerUpdate', 'tripId', tripId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('tripControllerUpdate', 'body', body)
            const localVarPath = `/api/catalog/trips/{trip_id}`
                .replace(`{${"trip_id"}}`, encodeURIComponent(String(tripId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TripControllerApi - functional programming interface
 * @export
 */
export const TripControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TripControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} tripId 
         * @param {CheckSeatsAvailabilityDto} checkSeatsAvailabilityDto 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tripControllerCanAcceptReservation(tripId: string, checkSeatsAvailabilityDto: CheckSeatsAvailabilityDto, xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tripControllerCanAcceptReservation(tripId, checkSeatsAvailabilityDto, xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tripId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tripControllerCancelTrip(tripId: string, xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Trip>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tripControllerCancelTrip(tripId, xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tripId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tripControllerDeleteItem(tripId: string, xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Trip>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tripControllerDeleteItem(tripId, xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tripId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tripControllerEndTrip(tripId: string, xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Trip>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tripControllerEndTrip(tripId, xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tripControllerGet(xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Trip>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tripControllerGet(xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tripId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tripControllerGetById(tripId: string, xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Trip>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tripControllerGetById(tripId, xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tripId 
         * @param {InitReservationPaymentDto} initReservationPaymentDto 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tripControllerInitTripReservationPayment(tripId: string, initReservationPaymentDto: InitReservationPaymentDto, xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tripControllerInitTripReservationPayment(tripId, initReservationPaymentDto, xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PublishTripDto} publishTripDto 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tripControllerPublish(publishTripDto: PublishTripDto, xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Trip>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tripControllerPublish(publishTripDto, xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tripId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tripControllerStartTrip(tripId: string, xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Trip>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tripControllerStartTrip(tripId, xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tripId 
         * @param {object} body 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tripControllerUpdate(tripId: string, body: object, xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Trip>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tripControllerUpdate(tripId, body, xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TripControllerApi - factory interface
 * @export
 */
export const TripControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TripControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} tripId 
         * @param {CheckSeatsAvailabilityDto} checkSeatsAvailabilityDto 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tripControllerCanAcceptReservation(tripId: string, checkSeatsAvailabilityDto: CheckSeatsAvailabilityDto, xAPIKEY?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.tripControllerCanAcceptReservation(tripId, checkSeatsAvailabilityDto, xAPIKEY, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tripId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tripControllerCancelTrip(tripId: string, xAPIKEY?: string, options?: any): AxiosPromise<Trip> {
            return localVarFp.tripControllerCancelTrip(tripId, xAPIKEY, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tripId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tripControllerDeleteItem(tripId: string, xAPIKEY?: string, options?: any): AxiosPromise<Trip> {
            return localVarFp.tripControllerDeleteItem(tripId, xAPIKEY, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tripId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tripControllerEndTrip(tripId: string, xAPIKEY?: string, options?: any): AxiosPromise<Trip> {
            return localVarFp.tripControllerEndTrip(tripId, xAPIKEY, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tripControllerGet(xAPIKEY?: string, options?: any): AxiosPromise<Array<Trip>> {
            return localVarFp.tripControllerGet(xAPIKEY, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tripId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tripControllerGetById(tripId: string, xAPIKEY?: string, options?: any): AxiosPromise<Trip> {
            return localVarFp.tripControllerGetById(tripId, xAPIKEY, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tripId 
         * @param {InitReservationPaymentDto} initReservationPaymentDto 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tripControllerInitTripReservationPayment(tripId: string, initReservationPaymentDto: InitReservationPaymentDto, xAPIKEY?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.tripControllerInitTripReservationPayment(tripId, initReservationPaymentDto, xAPIKEY, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PublishTripDto} publishTripDto 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tripControllerPublish(publishTripDto: PublishTripDto, xAPIKEY?: string, options?: any): AxiosPromise<Trip> {
            return localVarFp.tripControllerPublish(publishTripDto, xAPIKEY, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tripId 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tripControllerStartTrip(tripId: string, xAPIKEY?: string, options?: any): AxiosPromise<Trip> {
            return localVarFp.tripControllerStartTrip(tripId, xAPIKEY, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tripId 
         * @param {object} body 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tripControllerUpdate(tripId: string, body: object, xAPIKEY?: string, options?: any): AxiosPromise<Trip> {
            return localVarFp.tripControllerUpdate(tripId, body, xAPIKEY, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TripControllerApi - object-oriented interface
 * @export
 * @class TripControllerApi
 * @extends {BaseAPI}
 */
export class TripControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} tripId 
     * @param {CheckSeatsAvailabilityDto} checkSeatsAvailabilityDto 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TripControllerApi
     */
    public tripControllerCanAcceptReservation(tripId: string, checkSeatsAvailabilityDto: CheckSeatsAvailabilityDto, xAPIKEY?: string, options?: AxiosRequestConfig) {
        return TripControllerApiFp(this.configuration).tripControllerCanAcceptReservation(tripId, checkSeatsAvailabilityDto, xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tripId 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TripControllerApi
     */
    public tripControllerCancelTrip(tripId: string, xAPIKEY?: string, options?: AxiosRequestConfig) {
        return TripControllerApiFp(this.configuration).tripControllerCancelTrip(tripId, xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tripId 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TripControllerApi
     */
    public tripControllerDeleteItem(tripId: string, xAPIKEY?: string, options?: AxiosRequestConfig) {
        return TripControllerApiFp(this.configuration).tripControllerDeleteItem(tripId, xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tripId 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TripControllerApi
     */
    public tripControllerEndTrip(tripId: string, xAPIKEY?: string, options?: AxiosRequestConfig) {
        return TripControllerApiFp(this.configuration).tripControllerEndTrip(tripId, xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TripControllerApi
     */
    public tripControllerGet(xAPIKEY?: string, options?: AxiosRequestConfig) {
        return TripControllerApiFp(this.configuration).tripControllerGet(xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tripId 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TripControllerApi
     */
    public tripControllerGetById(tripId: string, xAPIKEY?: string, options?: AxiosRequestConfig) {
        return TripControllerApiFp(this.configuration).tripControllerGetById(tripId, xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tripId 
     * @param {InitReservationPaymentDto} initReservationPaymentDto 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TripControllerApi
     */
    public tripControllerInitTripReservationPayment(tripId: string, initReservationPaymentDto: InitReservationPaymentDto, xAPIKEY?: string, options?: AxiosRequestConfig) {
        return TripControllerApiFp(this.configuration).tripControllerInitTripReservationPayment(tripId, initReservationPaymentDto, xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PublishTripDto} publishTripDto 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TripControllerApi
     */
    public tripControllerPublish(publishTripDto: PublishTripDto, xAPIKEY?: string, options?: AxiosRequestConfig) {
        return TripControllerApiFp(this.configuration).tripControllerPublish(publishTripDto, xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tripId 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TripControllerApi
     */
    public tripControllerStartTrip(tripId: string, xAPIKEY?: string, options?: AxiosRequestConfig) {
        return TripControllerApiFp(this.configuration).tripControllerStartTrip(tripId, xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tripId 
     * @param {object} body 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TripControllerApi
     */
    public tripControllerUpdate(tripId: string, body: object, xAPIKEY?: string, options?: AxiosRequestConfig) {
        return TripControllerApiFp(this.configuration).tripControllerUpdate(tripId, body, xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserControllerApi - axios parameter creator
 * @export
 */
export const UserControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} email 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerFindAccountByEmail: async (email: string, xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('userControllerFindAccountByEmail', 'email', email)
            const localVarPath = `/api/user/find-by-email/{email}`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerFindAccountById: async (id: string, xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('userControllerFindAccountById', 'id', id)
            const localVarPath = `/api/user/find-by-id/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserControllerApi - functional programming interface
 * @export
 */
export const UserControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} email 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerFindAccountByEmail(email: string, xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerFindAccountByEmail(email, xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerFindAccountById(id: string, xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerFindAccountById(id, xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserControllerApi - factory interface
 * @export
 */
export const UserControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} email 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerFindAccountByEmail(email: string, xAPIKEY?: string, options?: any): AxiosPromise<User> {
            return localVarFp.userControllerFindAccountByEmail(email, xAPIKEY, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerFindAccountById(id: string, xAPIKEY?: string, options?: any): AxiosPromise<User> {
            return localVarFp.userControllerFindAccountById(id, xAPIKEY, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserControllerApi - object-oriented interface
 * @export
 * @class UserControllerApi
 * @extends {BaseAPI}
 */
export class UserControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} email 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public userControllerFindAccountByEmail(email: string, xAPIKEY?: string, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).userControllerFindAccountByEmail(email, xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public userControllerFindAccountById(id: string, xAPIKEY?: string, options?: AxiosRequestConfig) {
        return UserControllerApiFp(this.configuration).userControllerFindAccountById(id, xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UtilsControllerApi - axios parameter creator
 * @export
 */
export const UtilsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} placeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilsControllerGetParsePlaceId: async (placeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'placeId' is not null or undefined
            assertParamExists('utilsControllerGetParsePlaceId', 'placeId', placeId)
            const localVarPath = `/api/catalog/utils/googl-maps/parse-place-id`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (placeId !== undefined) {
                localVarQueryParameter['place_id'] = placeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilsControllerGetVehicleSketch: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/catalog/utils/vehicle-sketch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UtilsControllerApi - functional programming interface
 * @export
 */
export const UtilsControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UtilsControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} placeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilsControllerGetParsePlaceId(placeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VehicleSketch>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilsControllerGetParsePlaceId(placeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilsControllerGetVehicleSketch(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VehicleSketch>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.utilsControllerGetVehicleSketch(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UtilsControllerApi - factory interface
 * @export
 */
export const UtilsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UtilsControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} placeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilsControllerGetParsePlaceId(placeId: string, options?: any): AxiosPromise<Array<VehicleSketch>> {
            return localVarFp.utilsControllerGetParsePlaceId(placeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilsControllerGetVehicleSketch(options?: any): AxiosPromise<Array<VehicleSketch>> {
            return localVarFp.utilsControllerGetVehicleSketch(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UtilsControllerApi - object-oriented interface
 * @export
 * @class UtilsControllerApi
 * @extends {BaseAPI}
 */
export class UtilsControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} placeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilsControllerApi
     */
    public utilsControllerGetParsePlaceId(placeId: string, options?: AxiosRequestConfig) {
        return UtilsControllerApiFp(this.configuration).utilsControllerGetParsePlaceId(placeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilsControllerApi
     */
    public utilsControllerGetVehicleSketch(options?: AxiosRequestConfig) {
        return UtilsControllerApiFp(this.configuration).utilsControllerGetVehicleSketch(options).then((request) => request(this.axios, this.basePath));
    }
}


