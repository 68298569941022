import { Box } from "@mantine/core";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { Booking } from "../../../sdk/catalog";
import { useParams } from "react-router-dom";
import { bookingController } from "../../../config/sdk";
import { RenderBookings } from "@ui/organisms/booking-widgets/RenderBookings";



export default observer(function () {
    let [bookings, setBookings] = useState<Booking[]>([]);
    let { user_id } = useParams()
    function load() {
        bookingController.bookingControllerGetFilteredBookings(undefined, undefined, user_id).then(response => {
            setBookings(response.data)
        }).catch(err => {
        })
    }
    useEffect(() => {
        load();
    }, []);
    return <Box>
        <RenderBookings bookings={bookings} />
    </Box>
})