import { Box, Container } from "@mantine/core";
import { EditUserAccount } from "./EditUserAccount";


export default function () {
    return <Box>
        <Container my="xl">
            <EditUserAccount />
        </Container>
    </Box>
}