import {
  Box,
  Button,
  Divider,
  Grid,
  Group,
  Paper,
  Stack,
  Text,
} from "@mantine/core";
import {
  VerticalKeyValuePair,
  VerticalKeyValuePairLinkValue,
} from "@ui/molecules/text";

import { useParams } from "react-router-dom";
import { User } from "../../../../sdk/catalog";
import { useDriverProfileHook } from "../../../../hooks/user-hook";

export default function ({ user }: { user: User }) {
  let { user_id } = useParams();

  let {
    approveDriver,
    approveDriverVehicle,
    disapproveDriver,
    disapproveDriverVehicle,
    driverProfile,
    loadDriverProfile,
  } = useDriverProfileHook(user_id ?? "");

  let kyc = driverProfile?.driver_kyc;
  return (
    <Paper p={"12px"}>
      <Stack>
        <Group>
          <Text>Drive profile</Text>
          {driverProfile?.driver_approval_status == "APPROVED" ? (
            <Button color="red" onClick={disapproveDriver}>
              Disapprove
            </Button>
          ) : (
            <Button color="green" onClick={approveDriver}>
              Approve
            </Button>
          )}
        </Group>

        <Grid sx={{ background: "rgba(20,20,20,.5)" }} p={"12px"}>
          <Grid.Col md={6}>
            <VerticalKeyValuePair
              label="State"
              value={kyc?.driver_licence_number ?? ""}
            />
          </Grid.Col>

          <Grid.Col md={6}>
            <VerticalKeyValuePair
              label="Address"
              value={kyc?.driver_licence_front_page ?? ""}
            />
          </Grid.Col>

          <Grid.Col md={6}>
            <VerticalKeyValuePair
              label="Date of birth"
              value={kyc?.date_of_birth ?? ""}
            />
          </Grid.Col>
          <Grid.Col md={6}>
            <VerticalKeyValuePair
              label="Driver licence number"
              value={kyc?.driver_licence_number ?? ""}
            />
          </Grid.Col>
          <Grid.Col md={6}>
            <VerticalKeyValuePairLinkValue
              label="Drivce licence front page"
              value={kyc?.driver_licence_front_page ?? ""}
            />
          </Grid.Col>
          <Grid.Col md={6}>
            <VerticalKeyValuePairLinkValue
              label="Driver licence back page"
              value={kyc?.driver_licence_back_page ?? ""}
            />
          </Grid.Col>
          <Grid.Col md={6}>
            <VerticalKeyValuePairLinkValue
              label="Police character report"
              value={kyc?.police_character_report ?? ""}
            />
          </Grid.Col>

          <Grid.Col md={12}>
            <VerticalKeyValuePair label="About" value={kyc?.about ?? ""} />
          </Grid.Col>
        </Grid>
        <Divider />

        <Text>Guarantor 1</Text>
        <Grid sx={{ background: "rgba(20,20,20,.5)" }} p={"12px"}>
          <Grid.Col md={6}>
            <VerticalKeyValuePair
              label="Fullname"
              value={kyc?.guarantor1_fullname ?? ""}
            />
          </Grid.Col>
          <Grid.Col md={6}>
            <VerticalKeyValuePair
              label="Phone"
              value={kyc?.guarantor1_phone ?? ""}
            />
          </Grid.Col>
          <Grid.Col md={6}>
            <VerticalKeyValuePair
              label="Email"
              value={kyc?.guarantor1_email ?? ""}
            />
          </Grid.Col>
          <Grid.Col md={6}>
            <VerticalKeyValuePairLinkValue
              label="Government Id"
              value={kyc?.guarantor1_government_issued_id ?? ""}
            />
          </Grid.Col>
        </Grid>

        <Text>Guarantor 2</Text>
        <Grid sx={{ background: "rgba(20,20,20,.5)" }} p={"12px"}>
          <Grid.Col md={6}>
            <VerticalKeyValuePair
              label="Fullname"
              value={kyc?.guarantor2_fullname ?? ""}
            />
          </Grid.Col>
          <Grid.Col md={6}>
            <VerticalKeyValuePair
              label="Phone"
              value={kyc?.guarantor2_phone ?? ""}
            />
          </Grid.Col>
          <Grid.Col md={6}>
            <VerticalKeyValuePair
              label="Email"
              value={kyc?.guarantor2_email ?? ""}
            />
          </Grid.Col>
          <Grid.Col md={6}>
            <VerticalKeyValuePairLinkValue
              label="Government Id"
              value={kyc?.guarantor2_government_issued_id ?? ""}
            />
          </Grid.Col>
        </Grid>

        <Divider />

        <Group>
          <Text>Vehicle</Text>
          {driverProfile?.vehicle_approval_status == "APPROVED" ? (
            <Button color="red" onClick={disapproveDriverVehicle}>
              Disapprove
            </Button>
          ) : (
            <Button color="green" onClick={approveDriverVehicle}>
              Approve
            </Button>
          )}
        </Group>
        <Grid sx={{ background: "rgba(20,20,20,.5)" }} p={"12px"}>
          <Grid.Col md={6}>
            <VerticalKeyValuePair
              label="Company"
              value={driverProfile?.vehicle_company ?? ""}
            />
          </Grid.Col>
          <Grid.Col md={6}>
            <VerticalKeyValuePair
              label="Model"
              value={driverProfile?.vehicle_model ?? ""}
            />
          </Grid.Col>
          <Grid.Col md={6}>
            <VerticalKeyValuePair
              label="Year"
              value={driverProfile?.vehicle_year ?? ""}
            />
          </Grid.Col>
          <Grid.Col md={6}>
            <VerticalKeyValuePair
              label="Seats"
              value={driverProfile?.vehicle_seats ?? ""}
            />
          </Grid.Col>

          <Grid.Col md={6}>
            <img
              src={driverProfile?.vehicle_photo}
              style={{ display: "block", width: "100%" }}
            />
          </Grid.Col>
        </Grid>

        <Grid sx={{ background: "rgba(20,20,20,.5)" }} p={"12px"}>
          <Grid.Col md={6}>
            <VerticalKeyValuePairLinkValue
              label="Vehicle Licence"
              value={driverProfile?.vehicle_licence ?? ""}
            />
          </Grid.Col>
          <Grid.Col md={6}>
            <VerticalKeyValuePairLinkValue
              label="Certificate of road worthiness"
              value={driverProfile?.certificate_of_road_worthiness ?? ""}
            />
          </Grid.Col>
          <Grid.Col md={6}>
            <VerticalKeyValuePairLinkValue
              label="Proof of ownership"
              value={driverProfile?.proof_of_ownership ?? ""}
            />
          </Grid.Col>
          <Grid.Col md={6}>
            <VerticalKeyValuePair
              label="Vehicle VIN"
              value={driverProfile?.vehicle_vin ?? ""}
            />
          </Grid.Col>
        </Grid>
      </Stack>
    </Paper>
  );
}
