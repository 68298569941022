import { Box } from "@mantine/core";
import { RenderPayouts } from "@ui/organisms/payout-widgets/RenderPayouts";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";

import { payoutManager } from "@store/payout";
import { useParams } from "react-router-dom";
import { adminPayoutController } from "../../../../config/sdk";
import { Payout } from "../../../../sdk/catalog";


export default observer(function () {
    let [payouts, setPayouts] = useState<Payout[]>();
    let { user_id } = useParams()

    function load() {
        adminPayoutController.adminPayoutControllerGetAllUserPayouts(user_id ?? "").then(response => {
            setPayouts(response.data)
        }).catch(err => {
        })
    }
    useEffect(() => {
        load();
    }, []);

    return <Box>
        <RenderPayouts payouts={payoutManager.items} />
    </Box>
})