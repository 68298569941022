import { Grid, Group, Avatar, ActionIcon, Box, Button } from "@mantine/core";
import { payoutManager } from "@store/payout";
import { VerticalKeyValuePair } from "@ui/molecules/text";
import { ArrowDown2 } from "iconsax-react";
import { DataTable } from "mantine-datatable";
import { observer } from "mobx-react";
import { Payout } from "../../../sdk/catalog";
import { usePagination } from "../../../hooks/use-pagination";
import { useConfirm } from "../../../hooks/use-confirm";
import { formatCurrency } from "../../../utils";
import { adminPayoutController } from "../../../config/sdk";

export const RenderPayouts = observer(({ payouts }: { payouts: Payout[] }) => {
  let { genericConfirm } = useConfirm();
  let { page, records, setPage, setRecords, PAGE_SIZE } = usePagination<Payout>(
    {
      items: payouts,
    }
  );

  return (
    <DataTable
      height={500}
      striped={false}
      withColumnBorders
      style={{ paddingTop: 0 }}
      verticalSpacing="md"
      noRecordsIcon={true}
      borderRadius="xs"
      rowExpansion={{
        allowMultiple: true,
        content: (props) => {
          let data = props.record;
          return (
            <Box p="md" key={props.recordIndex}>
              <Box p="md" sx={{}}>
                <Grid>
                  <Grid.Col md={4}>
                    <VerticalKeyValuePair label="Status" value={data.status} />
                  </Grid.Col>
                  <Grid.Col md={4}>
                    <VerticalKeyValuePair
                      label="Created At"
                      value={`${new Date().toDateString()}`}
                    />
                  </Grid.Col>
                  <Grid.Col md={4}>
                    <VerticalKeyValuePair
                      label="Update At"
                      value={`${new Date().toDateString()}`}
                    />
                  </Grid.Col>

                  <Grid.Col md={4}>
                    <VerticalKeyValuePair
                      label="Amount"
                      value={formatCurrency(data.amount)}
                    />
                  </Grid.Col>
                  <Grid.Col md={4}>
                    <VerticalKeyValuePair
                      label="Payout type"
                      value={data.payout_type ?? "Manual"}
                    />
                  </Grid.Col>
                  <Grid.Col md={4}>
                    <Group position="right">
                
                      {data.status == "PENDING" ? (
                        <Button
                          onClick={async () => {
                            genericConfirm(
                              "Confirm action",
                              "Are you sure you made transfer to specified driver account",
                              async () => {
                                //@ts-ignore
                                await adminPayoutController.adminPayoutControllerUpdatePayout(
                                      //@ts-ignore
                                  data._id,
                                  {
                                    payment_status: "COMPLETED",
                                    status: "COMPLETED",
                                  }
                                );
                                payoutManager.loadItems();
                                window.location.replace("/");
                              }
                            );
                          }}
                        >
                          Clear Payout
                        </Button>
                      ) : null}
                    </Group>
                  </Grid.Col>
                </Grid>
              </Box>
            </Box>
          );
        },
      }}
      withBorder={false}
      columns={[
        {
          accessor: "id",
          title: "#",
          textAlignment: "center",
          width: 50,
          render: ({}) => (
            <Group position="center">
              <Box sx={{ width: 10, height: 10, background: "gray" }}></Box>
            </Group>
          ),
        },
        {
          accessor: "amount",
          title: "Total Amount",
        },
        {
          accessor: "status",
          title: "Status",
        },
        {
          accessor: "created_at",
          title: "Create at",
        },
        {
          accessor: "notes",
          title: "Notes",
        },
        {
          accessor: "*",
          title: "Action",
          width: 100,
          render: ({}) => (
            <Group>
              <ActionIcon>
                <ArrowDown2 variant="Bold" />
              </ActionIcon>
            </Group>
          ),
        },
      ]}
      records={records}
      totalRecords={payouts.length}
      recordsPerPage={PAGE_SIZE}
      page={page}
      onPageChange={(p) => setPage(p)}
    />
  );
});
