import { Box, Group, Paper, Stack, Title } from "@mantine/core";
import { bookingsManager } from "@store/bookings";
import { payoutManager } from "@store/payout";
import { RenderBookings } from "@ui/organisms/booking-widgets/RenderBookings";
import { observer } from "mobx-react";
import { useEffect } from "react";

export default observer(function BookingsScreen() {

  useEffect(() => {
    payoutManager.loadItems();
  }, []);
  
  return (
    <Box style={{ overflow: "hidden !important" }} mt="xl">
      <Stack>
        <Paper
          p={"18px"}
          sx={(theme) => {
            return { width: "100%" };
          }}
          shadow="md"
        >
          <Stack>
            <Group position="apart">
              <Box>
                <Title sx={{ fontSize: 24 }}>Bookings</Title>
              </Box>
            </Group>
            <Stack
              sx={(theme) => {
                return {
                  padding: 12,
                };
              }}
            >
              <RenderBookings bookings={bookingsManager.items ?? []} />
            </Stack>
          </Stack>
        </Paper>
      </Stack>
    </Box>
  );
});
