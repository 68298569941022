import {
  Avatar,
  Box,
  Divider,
  Group,
  Input,
  Paper,
  Stack,
  Title,
  Text,
  Grid,
  Center,
  Tabs,
  Button,
} from "@mantine/core";
import { usersManager } from "@store/users";
import { IconPhoto, IconMessageCircle, IconSettings } from "@tabler/icons";
import { HorizontalKeyValuePair } from "@ui/molecules/text";
import { NewAdminProcess } from "@ui/organisms/processes/new-admin-process";
import { DataTable } from "mantine-datatable";
import { observer } from "mobx-react";
import { useDriverProfileHook, useUserHook } from "../../../hooks/user-hook";
import { useParams } from "react-router-dom";
import DriverTabs from "./components/DriverTabs";

export default observer(function () {
  let { user_id } = useParams();
  let { userProfile, loadUserProfile, toggleProfileStatus } = useUserHook(
    user_id ?? ""
  );

  let { requestDriverPayout } = useDriverProfileHook(user_id ?? "");

  let paystack_int: any = userProfile?.paystack_int;

  console.log(paystack_int);

  return (
    <Box style={{ overflow: "hidden !important" }} mt="xl">
      <Stack>
        <Stack>
          <Group position="apart">
            <Box>
              <Title sx={{ fontSize: 24 }}>Manage account</Title>
            </Box>
          </Group>
          <Grid align="stretch">
            <Grid.Col md={6}>
              <Paper
                p={"18px"}
                sx={(theme) => {
                  return { width: "100%", height: "100%" };
                }}
                shadow="md"
              >
                <Stack>
                  <Group position="apart">
                    <Avatar size={"xl"} src={userProfile?.profile_photo} />
                    <Box>
                      {userProfile?.account_status == "ACTIVE" ? (
                        <Button color="red" onClick={toggleProfileStatus}>
                          Deactivate
                        </Button>
                      ) : (
                        <Button color="red" onClick={toggleProfileStatus}>
                          Activate
                        </Button>
                      )}

                      <Button color="green" onClick={requestDriverPayout}>
                        Init payout
                      </Button>
                    </Box>
                  </Group>

                  {userProfile?.paystack_int ? (
                    <Box>
                      <HorizontalKeyValuePair
                        label="Bank"
                        value={paystack_int.settlement_bank}
                      />
                      <HorizontalKeyValuePair
                        label="Account number"
                        value={paystack_int.account_number}
                      />
                      <HorizontalKeyValuePair
                        label="Business name"
                        value={paystack_int.business_name}
                      />
                    </Box>
                  ) : null}
                </Stack>
              </Paper>
            </Grid.Col>
            <Grid.Col md={6}>
              <Paper
                p={"18px"}
                sx={(theme) => {
                  return { width: "100%", height: "100%" };
                }}
                shadow="md"
              >
                <Stack>
                  <HorizontalKeyValuePair
                    label="First name"
                    value={userProfile?.first_name ?? ""}
                  />
                  <HorizontalKeyValuePair
                    label="Last name"
                    value={userProfile?.last_name ?? ""}
                  />
                  <HorizontalKeyValuePair
                    label="Account type"
                    value={userProfile?.account_type ?? ""}
                  />
                  <HorizontalKeyValuePair
                    label="Status"
                    value={userProfile?.account_status ?? ""}
                  />
                  <HorizontalKeyValuePair
                    label="Created on"
                    //@ts-ignore
                    value={new Date(userProfile?.created_at).toDateString()}
                  />
                </Stack>
              </Paper>
            </Grid.Col>
          </Grid>
        </Stack>
        {userProfile ? <DriverTabs user={userProfile} /> : null}
      </Stack>
    </Box>
  );
});
