import { Box } from "@mantine/core";
import { RenderTrips } from "@ui/organisms/trip-widgets/RenderTrips";
import { observer } from "mobx-react";
import { Trip } from "../../../../sdk/catalog";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { adminTripController } from "../../../../config/sdk";

export default observer(function () {
  let [trips, setTrips] = useState<Trip[]>([]);
  let { user_id } = useParams();

  function load() {
    adminTripController
      .adminTripControllerGetFiltered(undefined, user_id)
      .then((response) => {
        setTrips(response.data);
      })
      .catch((err) => {});
  }

  useEffect(() => {
    load();
  }, []);

  return (
    <Box>
      <RenderTrips trips={trips} />
    </Box>
  );
});
