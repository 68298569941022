import { AuthApi } from "../sdk/auth";
import axios from "axios";
import {
  BookingsControllerApi,
  TripControllerApi,
  PayoutControllerApi,
  ReservationControllerApi,
  RequestsControllerApi,
  UserControllerApi,
  ProfileControllerApi,
  AdminPayoutControllerApi,
  AdminDriverControllerApi,
  AdminUserControllerApi,
  AdminTripControllerApi,
  DriverControllerApi,
  ReviewControllerApi,
  AdminTicketControllerApi,
  AdminBookingsControllerApi,
} from "../sdk/catalog";

let config = {
  isJsonMime: (mime: any) => {
    return true;
  },
};



let axiosConfig = axios.create({
  headers: {
    "X-API-KEY": "bypass",
  },
});

export let reviewController = new ReviewControllerApi(config, undefined, axiosConfig);

export let authController = new AuthApi(config, undefined, axiosConfig);
export let userController = new UserControllerApi(
  config,
  undefined,
  axiosConfig
);

export let adminTicketController = new AdminTicketControllerApi(
  config,
  undefined,
  axiosConfig
);



export let adminBookignController = new AdminBookingsControllerApi(
  config,
  undefined,
  axiosConfig
);

export let driverController = new DriverControllerApi(
  config,
  undefined,
  axiosConfig
);


export let adminPayoutController = new AdminPayoutControllerApi(
  config,
  undefined,
  axiosConfig
);

export let adminDriverController = new AdminDriverControllerApi(
  config,
  undefined,
  axiosConfig
);

export let adminUserController = new AdminUserControllerApi(
  config,
  undefined,
  axiosConfig
);

export let adminTripController = new AdminTripControllerApi(
  config,
  undefined,
  axiosConfig
);




export let profileController = new ProfileControllerApi(
  config,
  undefined,
  axiosConfig
);



export let payoutController = new PayoutControllerApi(
  config,
  undefined,
  axiosConfig
);

export let reservationController = new ReservationControllerApi(
  config,
  undefined,
  axiosConfig
);

export let requestController = new RequestsControllerApi(
  config,
  undefined,
  axiosConfig
);

export let tripController = new TripControllerApi(
  config,
  undefined,
  axiosConfig
);

export let bookingController = new BookingsControllerApi(
  config,
  undefined,
  axiosConfig
);

export function configureClientSDK(token: string) {
  let config = {
    accessToken: token,
    isJsonMime: (mime: any) => {
      return true;
    },
  };

  adminTicketController = new AdminTicketControllerApi(
    config,
    undefined,
    axiosConfig
  );

  driverController = new DriverControllerApi(
    config,
    undefined,
    axiosConfig
  );


  authController = new AuthApi(config, undefined, axiosConfig);

  userController = new UserControllerApi(config, undefined, axiosConfig);

  profileController = new ProfileControllerApi(
    config,
    undefined,
    axiosConfig
  );


  payoutController = new PayoutControllerApi(config, undefined, axiosConfig);

  reservationController = new ReservationControllerApi(
    config,
    undefined,
    axiosConfig
  );

  requestController = new RequestsControllerApi(config, undefined, axiosConfig);

  tripController = new TripControllerApi(config, undefined, axiosConfig);
  reviewController = new ReviewControllerApi(config, undefined, axiosConfig);


  bookingController = new BookingsControllerApi(config, undefined, axiosConfig);


  adminPayoutController = new AdminPayoutControllerApi(
    config,
    undefined,
    axiosConfig
  );

  adminDriverController = new AdminDriverControllerApi(
    config,
    undefined,
    axiosConfig
  );

  adminUserController = new AdminUserControllerApi(
    config,
    undefined,
    axiosConfig
  );

  adminTripController = new AdminTripControllerApi(
    config,
    undefined,
    axiosConfig
  );


  localStorage.setItem("u-token", token);
}
