import {
  Avatar,
  Box,
  Center,
  Container,
  Grid,
  Textarea,
  TextInput,
  FileButton,
  Button,
  Stack,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { UpdateUserDto } from "../../../sdk/auth";
import { authManager } from "@store/account/auth";
import { uploadBoxedImage } from "@services/cloudinary";
import { useState } from "react";
import { profileController } from "../../../config/sdk";
import { showNotification } from "@mantine/notifications";

export const EditUserAccount = () => {

  let [loading, setLoading] = useState(false);

  let form = useForm<UpdateUserDto>({
    initialValues: {
      ...authManager.user,
      profile_photo: authManager.user.profile_photo,
      bio: authManager.user.bio,
      cover_photo: "",
      first_name: authManager.user.first_name,
      last_name: authManager.user.last_name
    }
  })
  return (
    <Container>
      <form onSubmit={form.onSubmit(async (values) => {
        try {
          setLoading(true);
          await profileController.profileControllerUpdateProfile(values);
          authManager.loadProfile();
          showNotification({ message: "Updated profile" })
          setLoading(false);
        } catch (e) {
          setLoading(false);
        }
      })}>
        <Stack>
          <Center>
            <FileButton
              onChange={async function (payload: File | null) {
                if (payload) {
                  let url = await uploadBoxedImage(payload);

                  form.setFieldValue("profile_photo", url ?? "");
                }
              }}
            >
              {(props) => {
                return <Avatar size={"xl"} {...props} src={form.values.profile_photo} />;
              }}
            </FileButton>
          </Center>
          <Grid>
            <Grid.Col md={6}>
              <TextInput label="First name"  {...form.getInputProps("first_name")} />
            </Grid.Col>
            <Grid.Col md={6}>
              <TextInput label="Last name"  {...form.getInputProps("last_name")} />
            </Grid.Col>

            <Grid.Col md={12}>
              <TextInput label="Phone" {...form.getInputProps("phone")} />
            </Grid.Col>
            <Grid.Col md={12}>
              <Textarea label="Bio"  {...form.getInputProps("bio")} />
            </Grid.Col>
          </Grid>
          <Center>
            <Button loading={loading} type="submit">Update</Button>
          </Center>
        </Stack>
      </form>
    </Container>
  );
};
