import { Avatar, Box, Button, Divider, Grid, Group, Stack, Text } from "@mantine/core";
import { Trip } from "../../../sdk/catalog";
import { VerticalKeyValuePair } from "@ui/molecules/text";
import { useUserHook } from "../../../hooks/user-hook";
import { Link } from "react-router-dom";



export default function ({ trip }: { trip: Trip }) {
    //@ts-ignore
    let { userProfile } = useUserHook(trip.driver_id);

    return <Box>
        <Box p="md" key={trip.id}>
            <Stack p="md" sx={{ background: "rgba(20,20,20,.5)" }}>
                <Group>
                    <Avatar src={userProfile?.profile_photo} />
                    <Box sx={{ flex: 1 }}>
                        <Text sx={{ textTransform: "capitalize" }}>{userProfile?.first_name} {userProfile?.last_name}</Text>
                        <Text>{userProfile?.driver_account_status}</Text>
                    </Box>
                    <Link to={`/drivers/${userProfile?.id ?? ""}`}>
                        <Button variant="subtle">View</Button>
                    </Link>
                </Group>
                <Divider />
                <Grid>
                    <Grid.Col md={4}>
                        <VerticalKeyValuePair label="From" value={trip.start_location.state} />
                    </Grid.Col>
                    <Grid.Col md={4}>
                        <VerticalKeyValuePair label="To" value={trip.end_location.state} />
                    </Grid.Col>
                    <Grid.Col md={4}>
                        <VerticalKeyValuePair
                            label="Starts at"
                            value={`${new Date(trip.trip_starts_at
                            ).toDateString()}`}
                        />
                    </Grid.Col>
                    <Grid.Col md={4}>
                        <VerticalKeyValuePair label="Intiatd by" value={trip.initiated_by} />
                    </Grid.Col>
                    <Grid.Col md={4}>
                        <VerticalKeyValuePair label="Status" value={trip.status} />
                    </Grid.Col>
                    <Grid.Col md={4}>
                        <VerticalKeyValuePair
                            label="Created At"
                            value={`${new Date(
                            ).toDateString()}`}
                        />
                    </Grid.Col>
                    <Grid.Col md={4}>
                        <VerticalKeyValuePair
                            label="Update At"
                            value={`${new Date(

                            ).toDateString()}`}
                        />
                    </Grid.Col>
                </Grid>
            </Stack>
        </Box>
    </Box>
}