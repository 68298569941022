import { bookingsManager } from "@store/bookings";
import { payoutManager } from "@store/payout";
import { ticketsManager } from "@store/tickets";
import { tripsManager } from "@store/trips";
import { usersManager } from "@store/users";
import { useEffect } from "react";

export function useUtilsLoader() {
  function load() {
    Promise.all([
      usersManager.loadItems(),
      payoutManager.loadItems(),
      tripsManager.loadItems(),
      bookingsManager.loadItems(),
      ticketsManager.loadItems(),
    ]).then((err) => {
    });
  }
  useEffect(() => {
    load();
  }, []);

  return { load }
}
