import {} from "@tabler/icons";
import {
  Notification,
  ArchiveBook,
  People,
  BagHappy,
  Activity,
  Profile2User,
  Bill,
  ArrowLeft,
  ArrowCircleLeft,
  Radar2,
  UserOctagon,
  User,
  Ticket2,
  Car,
  DirectSend,
} from "iconsax-react";
import { LinksGroupProps } from "./components";

export const navigationLinks: LinksGroupProps[] = [
  { label: "Overview", icon: Activity, link: "/" },
  { label: "Bookings", icon: ArchiveBook, link: "/bookings" },
  { label: "Passengers", icon: People, link: "/users" },
  { label: "Drivers", icon: Car, link: "/drivers" },
  { label: "Verification Request", icon: DirectSend, link: "/requests" },
  { label: "Trips", icon: Radar2, link: "/trips" },
  { label: "Tickets", icon: Ticket2, link: "/tickets" },
  {
    label: "Payouts",
    icon: Bill,
    link: "/payouts",
  },
  {
    label: "Profile",
    icon: User,
    link: "/account",
  },
];
