import { Grid, Group, Avatar, ActionIcon, Box, Button, Text } from "@mantine/core";
import { payoutManager } from "@store/payout";
import { VerticalKeyValuePair } from "@ui/molecules/text";
import { ArrowDown2 } from "iconsax-react";
import { DataTable } from "mantine-datatable";
import { observer } from "mobx-react";
import { Booking, Payout } from "../../../sdk/catalog";
import { Link } from "react-router-dom";
import { usePagination } from "../../../hooks/use-pagination";

export const RenderBookings = observer(({ bookings }: { bookings: Booking[] }) => {
    let { page, records, setPage, setRecords, PAGE_SIZE } =
    usePagination<Booking>({
      items:bookings,
    });

    return <DataTable
        striped={false}
        withColumnBorders
        style={{ paddingTop: 0 }}
        verticalSpacing="md"
        noRecordsIcon={true}
        borderRadius="xs"
        rowExpansion={{
            allowMultiple: true,
            content: (props) => {
                let data = props.record;
                return (
                    <Box p="md" key={props.recordIndex}>
                        <Box p="md" sx={{ background: "ghostwhite" }}>
                            <Grid>
                                <Grid.Col md={4}>
                                    <VerticalKeyValuePair label="Status" value={``} />
                                </Grid.Col>
                                <Grid.Col md={4}>
                                    <VerticalKeyValuePair
                                        label="Created At"
                                        value={`${new Date(

                                        ).toDateString()}`}
                                    />
                                </Grid.Col>
                                <Grid.Col md={4}>
                                    <VerticalKeyValuePair
                                        label="Update At"
                                        value={`${new Date(

                                        ).toDateString()}`}
                                    />
                                </Grid.Col>
                            </Grid>

                            <Group position="right">

                            </Group>
                        </Box>
                    </Box>
                );
            },
        }}
        withBorder={false}
        columns={[
            {
                accessor: "id",
                title: "#",
                textAlignment: "center",
                width: 50,
                render: ({ }) => (
                    <Group position="center">
                        <Box
                            sx={{ width: 10, height: 10, background: "gray" }}
                        ></Box>
                    </Group>
                ),
            },
            {
                accessor: "created_at",
                title: "Created at",
                render: (record) => {
                    return <Text >{new Date(record.created_at).toDateString()}</Text>
                }
            },
            {
                accessor: "*",
                title: "Passenger",
                render: ({ id }) => {
                    return <Link to={`/users/${id}`}>
                        <Button variant="default" size="sm">View</Button>
                    </Link>
                }
            },
            {
                accessor: "status",
                title: "Status",
            },
            {
                accessor: "amount_payable",
                title: "Total Amount",
            },
            {
                accessor: "notes",
                title: "Notes",
            },
            {
                accessor: "*",
                title: "Action",
                width: 100,
                render: ({ }) => (
                    <Group>
                        <ActionIcon>
                            <ArrowDown2 variant="Bold" />
                        </ActionIcon>
                    </Group>
                ),
            },
        ]}
        records={records}
        totalRecords={bookings.length}
        recordsPerPage={PAGE_SIZE}
        page={page}
        onPageChange={(p) => setPage(p)}
    />
})