import { useEffect, useState } from "react";
import { LoginDtoAccountTypeEnum, User } from "../sdk/auth";
import {
  adminDriverController,
  adminPayoutController,
  adminUserController,
  driverController,
  userController,
} from "../config/sdk";
import { Driver, UpdateAccountStatusAccountStatusEnum } from "../sdk/catalog";
import {
  resetNavigationProgress,
  startNavigationProgress,
} from "@mantine/nprogress";
import { showNotification } from "@mantine/notifications";
import { payoutManager } from "@store/payout";

export function useUserHook(user_id: string) {
  let [userProfile, setUserProfile] = useState<User>();

  function makeAdmin() {
    adminUserController
      .adminUserControllerMakeAdmin(user_id ?? "", { account_status: "ACTIVE" })
      .then((response) => {
        loadUserProfile();
        showNotification({ message: "Updated user type successfully" });
      });
  }

  function removeAdmin() {
    adminUserController
      .adminUserControllerRemoveAdminFlag(user_id ?? "")
      .then((response) => {
        loadUserProfile();
        showNotification({ message: "Removed admin flag successfully" });
      });
  }

  function loadUserProfile() {
    userController.userControllerFindAccountById(user_id).then((response) => {
      setUserProfile(response.data);
    });
  }
  useEffect(() => {
    loadUserProfile();
  }, []);
  function toggleProfileStatus() {
    let status =
      userProfile?.account_status ==
        UpdateAccountStatusAccountStatusEnum.Pending ||
      userProfile?.account_status ==
        UpdateAccountStatusAccountStatusEnum.Disabled
        ? UpdateAccountStatusAccountStatusEnum.Active
        : UpdateAccountStatusAccountStatusEnum.Disabled;
    startNavigationProgress();
    adminUserController
      .adminUserControllerUpdateAccountStatus(user_id, {
        account_status: status,
      })
      .then((response) => {
        setUserProfile(response.data);
        resetNavigationProgress();
      })
      .catch((err) => {
        resetNavigationProgress();
      });
  }

  function toggleDriverProfileStatus() {
    if (userProfile?.account_type !== LoginDtoAccountTypeEnum.Driver) {
      showNotification({ message: "User not eligible" });
      return;
    }
    let status =
      userProfile?.driver_account_status ==
        UpdateAccountStatusAccountStatusEnum.Pending ||
      userProfile?.account_status ==
        UpdateAccountStatusAccountStatusEnum.Disabled
        ? UpdateAccountStatusAccountStatusEnum.Active
        : UpdateAccountStatusAccountStatusEnum.Disabled;
    startNavigationProgress();
    adminUserController
      .adminUserControllerUpdateDriverAccountStatus(user_id, {
        driver_account_status: status,
      })
      .then((response) => {
        setUserProfile(response.data);
        resetNavigationProgress();
      })
      .catch((err) => {
        resetNavigationProgress();
      });
  }

  return {
    userProfile,
    loadUserProfile,
    toggleDriverProfileStatus,
    toggleProfileStatus,
    makeAdmin,
    removeAdmin
  };
}

export function useDriverProfileHook(driver_id: string) {
  let [driverProfile, setDriverProfile] = useState<Driver>();
  function loadDriverProfile() {
    driverController
      .driverControllerGetDriverProfile(driver_id)
      .then((response) => {
        setDriverProfile(response.data);
      });
  }

  async function approveDriverVehicle() {
    startNavigationProgress();
    try {
      let response =
        await adminDriverController.adminDriverControllerApproveVehicleProfile(
          driver_id
        );
      setDriverProfile(response.data);
      resetNavigationProgress();
    } catch (err) {
      resetNavigationProgress();
    }
  }

  async function disapproveDriverVehicle() {
    startNavigationProgress();
    try {
      let response =
        await adminDriverController.adminDriverControllerDisapproveVehicleProfile(
          driver_id
        );
      setDriverProfile(response.data);
      resetNavigationProgress();
    } catch (err) {
      resetNavigationProgress();
    }
  }

  async function approveDriver() {
    startNavigationProgress();
    try {
      let response =
        await adminDriverController.adminDriverControllerApproveDriverProfile(
          driver_id
        );
      setDriverProfile(response.data);
      resetNavigationProgress();
    } catch (err) {
      resetNavigationProgress();
    }
  }

  async function disapproveDriver() {
    startNavigationProgress();
    try {
      let response =
        await adminDriverController.adminDriverControllerDisapproveDriverProfile(
          driver_id
        );
      setDriverProfile(response.data);
      resetNavigationProgress();
    } catch (err) {
      resetNavigationProgress();
    }
  }

  async function requestDriverPayout() {
    startNavigationProgress();
    try {
      let response =
        await adminPayoutController.adminPayoutControllerRequestDriverPayout(
          driver_id
        );
      showNotification({
        message: "Payout will be processed in the background...",
      });
      resetNavigationProgress();
    } catch (err) {
      resetNavigationProgress();
    }
  }

  useEffect(() => {
    loadDriverProfile();
  }, []);
  return {
    driverProfile,
    loadDriverProfile,
    disapproveDriver,
    approveDriver,
    disapproveDriverVehicle,
    approveDriverVehicle,
    requestDriverPayout,
  };
}
