/* tslint:disable */
/* eslint-disable */
/**
 * Authorization Docs
 * Basic user authorization features
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AuthenticatedUser
 */
export interface AuthenticatedUser {
    /**
     * 
     * @type {string}
     * @memberof AuthenticatedUser
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface ForgottenPasswordDto
 */
export interface ForgottenPasswordDto {
    /**
     * 
     * @type {string}
     * @memberof ForgottenPasswordDto
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface Location
 */
export interface Location {
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    'state': string;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    'postal_code': string;
    /**
     * 
     * @type {object}
     * @memberof Location
     */
    'geometry': object;
}
/**
 * 
 * @export
 * @interface LoginDto
 */
export interface LoginDto {
    /**
     * 
     * @type {string}
     * @memberof LoginDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof LoginDto
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof LoginDto
     */
    'account_type': LoginDtoAccountTypeEnum;
}

export const LoginDtoAccountTypeEnum = {
    Admin: 'ADMIN',
    Driver: 'DRIVER',
    Individual: 'INDIVIDUAL'
} as const;

export type LoginDtoAccountTypeEnum = typeof LoginDtoAccountTypeEnum[keyof typeof LoginDtoAccountTypeEnum];

/**
 * 
 * @export
 * @interface PaystackTransferReceipient
 */
export interface PaystackTransferReceipient {
    /**
     * 
     * @type {boolean}
     * @memberof PaystackTransferReceipient
     */
    'active': boolean;
    /**
     * 
     * @type {string}
     * @memberof PaystackTransferReceipient
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof PaystackTransferReceipient
     */
    'currency': string;
    /**
     * 
     * @type {string}
     * @memberof PaystackTransferReceipient
     */
    'recipient_code': string;
    /**
     * 
     * @type {string}
     * @memberof PaystackTransferReceipient
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface ResetPasswordDto
 */
export interface ResetPasswordDto {
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordDto
     */
    'passwordResetToken': string;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordDto
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface SignUpDto
 */
export interface SignUpDto {
    /**
     * 
     * @type {string}
     * @memberof SignUpDto
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof SignUpDto
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof SignUpDto
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof SignUpDto
     */
    'account_type': SignUpDtoAccountTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SignUpDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof SignUpDto
     */
    'password': string;
}

export const SignUpDtoAccountTypeEnum = {
    Driver: 'DRIVER',
    Individual: 'INDIVIDUAL'
} as const;

export type SignUpDtoAccountTypeEnum = typeof SignUpDtoAccountTypeEnum[keyof typeof SignUpDtoAccountTypeEnum];

/**
 * 
 * @export
 * @interface UpdateUserDto
 */
export interface UpdateUserDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'bio': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'profile_photo': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'cover_photo': string;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'email': string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'email_activated': boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'metrics': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'last_name': string;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'driver_trips_count': number;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'passenger_trips_count': number;
    /**
     * 
     * @type {Location}
     * @memberof User
     */
    'location': Location;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'account_type': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'account_status': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'driver_account_status': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'passwordResetToken': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'passwordResetExpires': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'activationToken': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'activationExpires': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'profile_photo': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'cover_photo': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'bio': string;
    /**
     * 
     * @type {PaystackTransferReceipient}
     * @memberof User
     */
    'transfer_receipient': PaystackTransferReceipient;
    /**
     * 
     * @type {object}
     * @memberof User
     */
    'paystack_int': object;
}
/**
 * 
 * @export
 * @interface UserProfileResponse
 */
export interface UserProfileResponse {
    /**
     * 
     * @type {UserPublicData}
     * @memberof UserProfileResponse
     */
    'user': UserPublicData;
}
/**
 * 
 * @export
 * @interface UserPublicData
 */
export interface UserPublicData {
    /**
     * 
     * @type {string}
     * @memberof UserPublicData
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserPublicData
     */
    'email': string;
    /**
     * 
     * @type {boolean}
     * @memberof UserPublicData
     */
    'email_activated': boolean;
    /**
     * 
     * @type {string}
     * @memberof UserPublicData
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof UserPublicData
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof UserPublicData
     */
    'account_type': string;
    /**
     * 
     * @type {string}
     * @memberof UserPublicData
     */
    'account_status': string;
    /**
     * 
     * @type {string}
     * @memberof UserPublicData
     */
    'profile_photo': string;
    /**
     * 
     * @type {string}
     * @memberof UserPublicData
     */
    'cover_photo': string;
    /**
     * 
     * @type {string}
     * @memberof UserPublicData
     */
    'bio': string;
    /**
     * 
     * @type {string}
     * @memberof UserPublicData
     */
    'phone': string;
    /**
     * 
     * @type {PaystackTransferReceipient}
     * @memberof UserPublicData
     */
    'transfer_receipient': PaystackTransferReceipient;
    /**
     * 
     * @type {object}
     * @memberof UserPublicData
     */
    'paystack_int': object;
}

/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} userId 
         * @param {string} activationToken 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerActivate: async (userId: string, activationToken: string, xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('authControllerActivate', 'userId', userId)
            // verify required parameter 'activationToken' is not null or undefined
            assertParamExists('authControllerActivate', 'activationToken', activationToken)
            const localVarPath = `/api/auth/activate/{userId}/{activationToken}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"activationToken"}}`, encodeURIComponent(String(activationToken)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ForgottenPasswordDto} forgottenPasswordDto 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerForgottenPassword: async (forgottenPasswordDto: ForgottenPasswordDto, xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forgottenPasswordDto' is not null or undefined
            assertParamExists('authControllerForgottenPassword', 'forgottenPasswordDto', forgottenPasswordDto)
            const localVarPath = `/api/auth/forgotten-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forgottenPasswordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerGetProfile: async (xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LoginDto} loginDto 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerLogin: async (loginDto: LoginDto, xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginDto' is not null or undefined
            assertParamExists('authControllerLogin', 'loginDto', loginDto)
            const localVarPath = `/api/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LoginDto} loginDto 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerLoginAdmin: async (loginDto: LoginDto, xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginDto' is not null or undefined
            assertParamExists('authControllerLoginAdmin', 'loginDto', loginDto)
            const localVarPath = `/api/auth/login-admin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerResendActivationToken: async (xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/resend-activation-credentials`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ResetPasswordDto} resetPasswordDto 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerResetPassword: async (resetPasswordDto: ResetPasswordDto, xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resetPasswordDto' is not null or undefined
            assertParamExists('authControllerResetPassword', 'resetPasswordDto', resetPasswordDto)
            const localVarPath = `/api/auth/reset-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetPasswordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SignUpDto} signUpDto 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerSignup: async (signUpDto: SignUpDto, xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'signUpDto' is not null or undefined
            assertParamExists('authControllerSignup', 'signUpDto', signUpDto)
            const localVarPath = `/api/auth/signup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(signUpDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateUserDto} updateUserDto 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerUpdateUser: async (updateUserDto: UpdateUserDto, xAPIKEY?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateUserDto' is not null or undefined
            assertParamExists('authControllerUpdateUser', 'updateUserDto', updateUserDto)
            const localVarPath = `/api/auth/update-profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (xAPIKEY !== undefined && xAPIKEY !== null) {
                localVarHeaderParameter['X-API-KEY'] = String(xAPIKEY);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} userId 
         * @param {string} activationToken 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerActivate(userId: string, activationToken: string, xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerActivate(userId, activationToken, xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ForgottenPasswordDto} forgottenPasswordDto 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerForgottenPassword(forgottenPasswordDto: ForgottenPasswordDto, xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerForgottenPassword(forgottenPasswordDto, xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerGetProfile(xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserProfileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerGetProfile(xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {LoginDto} loginDto 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerLogin(loginDto: LoginDto, xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthenticatedUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerLogin(loginDto, xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {LoginDto} loginDto 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerLoginAdmin(loginDto: LoginDto, xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthenticatedUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerLoginAdmin(loginDto, xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerResendActivationToken(xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerResendActivationToken(xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ResetPasswordDto} resetPasswordDto 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerResetPassword(resetPasswordDto: ResetPasswordDto, xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerResetPassword(resetPasswordDto, xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SignUpDto} signUpDto 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerSignup(signUpDto: SignUpDto, xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthenticatedUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerSignup(signUpDto, xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateUserDto} updateUserDto 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerUpdateUser(updateUserDto: UpdateUserDto, xAPIKEY?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerUpdateUser(updateUserDto, xAPIKEY, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @param {string} userId 
         * @param {string} activationToken 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerActivate(userId: string, activationToken: string, xAPIKEY?: string, options?: any): AxiosPromise<void> {
            return localVarFp.authControllerActivate(userId, activationToken, xAPIKEY, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForgottenPasswordDto} forgottenPasswordDto 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerForgottenPassword(forgottenPasswordDto: ForgottenPasswordDto, xAPIKEY?: string, options?: any): AxiosPromise<void> {
            return localVarFp.authControllerForgottenPassword(forgottenPasswordDto, xAPIKEY, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerGetProfile(xAPIKEY?: string, options?: any): AxiosPromise<UserProfileResponse> {
            return localVarFp.authControllerGetProfile(xAPIKEY, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoginDto} loginDto 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerLogin(loginDto: LoginDto, xAPIKEY?: string, options?: any): AxiosPromise<AuthenticatedUser> {
            return localVarFp.authControllerLogin(loginDto, xAPIKEY, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoginDto} loginDto 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerLoginAdmin(loginDto: LoginDto, xAPIKEY?: string, options?: any): AxiosPromise<AuthenticatedUser> {
            return localVarFp.authControllerLoginAdmin(loginDto, xAPIKEY, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerResendActivationToken(xAPIKEY?: string, options?: any): AxiosPromise<void> {
            return localVarFp.authControllerResendActivationToken(xAPIKEY, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ResetPasswordDto} resetPasswordDto 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerResetPassword(resetPasswordDto: ResetPasswordDto, xAPIKEY?: string, options?: any): AxiosPromise<void> {
            return localVarFp.authControllerResetPassword(resetPasswordDto, xAPIKEY, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SignUpDto} signUpDto 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerSignup(signUpDto: SignUpDto, xAPIKEY?: string, options?: any): AxiosPromise<AuthenticatedUser> {
            return localVarFp.authControllerSignup(signUpDto, xAPIKEY, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateUserDto} updateUserDto 
         * @param {string} [xAPIKEY] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerUpdateUser(updateUserDto: UpdateUserDto, xAPIKEY?: string, options?: any): AxiosPromise<User> {
            return localVarFp.authControllerUpdateUser(updateUserDto, xAPIKEY, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @param {string} userId 
     * @param {string} activationToken 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerActivate(userId: string, activationToken: string, xAPIKEY?: string, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerActivate(userId, activationToken, xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForgottenPasswordDto} forgottenPasswordDto 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerForgottenPassword(forgottenPasswordDto: ForgottenPasswordDto, xAPIKEY?: string, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerForgottenPassword(forgottenPasswordDto, xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerGetProfile(xAPIKEY?: string, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerGetProfile(xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoginDto} loginDto 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerLogin(loginDto: LoginDto, xAPIKEY?: string, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerLogin(loginDto, xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoginDto} loginDto 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerLoginAdmin(loginDto: LoginDto, xAPIKEY?: string, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerLoginAdmin(loginDto, xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerResendActivationToken(xAPIKEY?: string, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerResendActivationToken(xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ResetPasswordDto} resetPasswordDto 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerResetPassword(resetPasswordDto: ResetPasswordDto, xAPIKEY?: string, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerResetPassword(resetPasswordDto, xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SignUpDto} signUpDto 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerSignup(signUpDto: SignUpDto, xAPIKEY?: string, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerSignup(signUpDto, xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateUserDto} updateUserDto 
     * @param {string} [xAPIKEY] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerUpdateUser(updateUserDto: UpdateUserDto, xAPIKEY?: string, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerUpdateUser(updateUserDto, xAPIKEY, options).then((request) => request(this.axios, this.basePath));
    }
}


