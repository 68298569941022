import React from "react";
import {
  BrowserRouter,
  BrowserRouter as Router,
  useNavigate,
} from "react-router-dom";
import { observer } from "mobx-react";
import { MantineProvider } from "@mantine/core";
import { NotificationsProvider } from "@mantine/notifications";
import MainAppRoutes from "./router/MainAppRoutes";
import { MaintineThemeConfig } from "../styles/mantine-config";
import { SpotlightProvider, openSpotlight } from "@mantine/spotlight";
import type { SpotlightAction } from "@mantine/spotlight";
import axios from "axios";
import AppAuthRoutes from "./router/AppAuthRoutes";
import { authManager } from "@store/account/auth";
import {
  IconDashboard,
  IconFileText,
  IconHome,
  IconSearch,
} from "@tabler/icons";
import { NavigationProgress } from "@mantine/nprogress";
import { PubNubProvider } from "pubnub-react";
import { pubnub } from "../config/pubnub";
import { ModalsProvider } from "@mantine/modals";

export default observer(function () {
  let [appState, setAppState] = React.useState("loaded");

  React.useEffect(() => {
    authManager.init();
  }, []);
  // return <div>I AM WORKING</div>;

  let authStatus = "";
  authStatus = authManager.status;

  const actions: SpotlightAction[] = [
    {
      title: "Home",
      description: "Get to home page",
      onTrigger: () => {},
      icon: <IconHome size={18} />,
    },
    {
      title: "Dashboard",
      description: "Get full information about current system status",
      onTrigger: () => {},
      icon: <IconDashboard size={18} />,
    },
    {
      title: "API Docs",
      description: "Visit API Docs to gain more access",
      onTrigger: () => window.open("https://internal-docs.etoju.ng"),
      icon: <IconFileText size={18} />,
    },
    {
      title: "Documentation",
      description: "Visit documentation to lean more on how features work",
      onTrigger: () => window.open("https://internal-technical-docs.etoju.ng"),
      icon: <IconFileText size={18} />,
    },
  ];

  return (
    <React.Fragment>
      <PubNubProvider client={pubnub}>
        <BrowserRouter>
          <MantineProvider
            theme={MaintineThemeConfig}
            withGlobalStyles
            withNormalizeCSS
          >
            <ModalsProvider>
              <NavigationProgress />
              <SpotlightProvider
                actions={actions}
                searchIcon={<IconSearch size={18} />}
                searchPlaceholder="Search..."
                shortcut="mod + shift + 1"
                nothingFoundMessage="Nothing found..."
              >
                <NotificationsProvider>
                  {authManager.user?.id !== undefined ? (
                    <React.Fragment>
                      <MainAppRoutes />
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <AppAuthRoutes />
                    </React.Fragment>
                  )}
                </NotificationsProvider>
              </SpotlightProvider>
            </ModalsProvider>
          </MantineProvider>
        </BrowserRouter>
      </PubNubProvider>
    </React.Fragment>
  );
});
