import { useState, useEffect } from "react";

const PAGE_SIZE = 10;

export function usePagination<T>({ items }: { items: T[] }) {
  const [page, setPage] = useState(1);
  const [records, setRecords] = useState(items.slice(0, PAGE_SIZE));

  useEffect(() => {
    load();
  }, [page]);

  function load() {
    const from = (page - 1) * PAGE_SIZE;
    const to = from + PAGE_SIZE;
    setRecords(items.slice(from, to));
  }

  return {
    page,
    setPage,
    records,
    setRecords,
    PAGE_SIZE,
    load,
  };
}
