import { openConfirmModal } from "@mantine/modals";
import { Text } from "@mantine/core";

export function useConfirm() {
  function genericConfirm(title: string, message: string, action: () => void) {
    console.log("clicked")
    openConfirmModal({
      title: title,
      children: <Text size="sm">{message}</Text>,
      labels: { confirm: "Confirm", cancel: "Cancel" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => action(),
    });

  }

  function confirmDelete(action: () => void) {
    openConfirmModal({
      title: "Please confirm your action",
      children: (
        <Text size="sm">
          Are you sure you want to delete this item? This action cannot be
          undone.
        </Text>
      ),
      labels: { confirm: "Confirm", cancel: "Cancel" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => action(),
    });
  }

  function confirmUpdate(action: () => void) {
    openConfirmModal({
      title: "Please confirm your action",
      children: (
        <Text size="sm">
          Are you sure you want to update this resource? This action cannot be
          undone.
        </Text>
      ),
      labels: { confirm: "Confirm", cancel: "Cancel" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => action(),
      
    });
  }

  return { confirmDelete, confirmUpdate,genericConfirm };
}
