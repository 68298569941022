import { Box, Paper, Tabs } from "@mantine/core";
import UserReviews from "../UserReviews";
import UserPayouts from "../UserPayouts";
import UserBookings from "../UserBookings";
import { User } from "../../../../sdk/catalog";

export default function () {
  return (
    <Box>
      <Paper
        p={"18px"}
        sx={(theme) => {
          return { width: "100%" };
        }}
        shadow="md"
      >
        <Tabs defaultValue="bookings">
          <Tabs.List sx={{ borderBottom: "none" }}>
            <Tabs.Tab value="bookings">Bookings</Tabs.Tab>
            <Tabs.Tab value="payouts">Payouts</Tabs.Tab>
            <Tabs.Tab value="reviews">Reviews</Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="bookings" pt="xs">
            <UserBookings />
          </Tabs.Panel>

          <Tabs.Panel value="payouts" pt="xs">
            <UserPayouts />
          </Tabs.Panel>

          <Tabs.Panel value="reviews" pt="xs">
            <UserReviews />
          </Tabs.Panel>
        </Tabs>
      </Paper>
    </Box>
  );
}
