import {
  Avatar,
  Box,
  Divider,
  Group,
  Input,
  Paper,
  Stack,
  Title,
  Text,
} from "@mantine/core";
import { usersManager } from "@store/users";
import { NewAdminProcess } from "@ui/organisms/processes/new-admin-process";
import RenderUsers from "@ui/organisms/user-widgets/RenderUsers";
import { DataTable } from "mantine-datatable";
import { observer } from "mobx-react";

export default observer(function SystemUsersScreen() {
  return (
    <Box style={{ overflow: "hidden !important" }} mt="xl">
      <Stack>
        <Paper
          p={"18px"}
          sx={(theme) => {
            return { width: "100%" };
          }}
          shadow="md"
        >
          <Stack>
            <Group position="apart">
              <Box>
                <Title sx={{ fontSize: 24 }}>System users</Title>
              </Box>
              <Group>
                <Input radius={"xl"} placeholder="Search Customer" />
                <NewAdminProcess />
              </Group>
            </Group>
            <Divider />
            <RenderUsers users={usersManager.admins} />
          </Stack>
        </Paper>
      </Stack>
    </Box>
  );
});
