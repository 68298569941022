import { makeAutoObservable, observable, runInAction } from "mobx";
import { authController, configureClientSDK } from "../../config/sdk";
import { UserPublicData } from "../../sdk/auth";
import { pubnub } from "../../config/pubnub";

class AuthManager {
  //@ts-ignore
  user: UserPublicData;
  status: "AUTHENTICATED" | "INITIAL" = "INITIAL";
  constructor() {
    makeAutoObservable(this, { user: observable });
  }

  initAccountToken(token: string) {
    localStorage.setItem("u-token", token);
    this.init();
  }

  init() {
    let token = localStorage.getItem("u-token");
    if (token) {
      configureClientSDK(token);
      runInAction(() => {
        this.status = "AUTHENTICATED";
      });
      this.loadProfile();
    }
  }

  async loadProfile() {
    try {
      let response = await authController.authControllerGetProfile();
      runInAction(() => {
        this.user = response.data.user;
        pubnub.setUUID(response.data.user.id)
      });
    } catch (err) { }
  }

  async logout() {
    try {
      localStorage.clear()
      runInAction(() => {
        //@ts-ignore
        this.user = {}
        this.status = "INITIAL"
      });
    } catch (err) { }
  }
}

export const authManager = new AuthManager();
