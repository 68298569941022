import { DataTable } from "mantine-datatable";
import { User } from "../../../sdk/catalog";
import { Box, Button, Group, Text } from "@mantine/core";
import { Link } from "react-router-dom";
import { usePagination } from "../../../hooks/use-pagination";

export default function ({ users }: { users: User[] }) {
  let { page, records, setPage, setRecords, PAGE_SIZE } = usePagination<User>({
    items: users,
  });

  return (
    <DataTable
      striped={false}
      withColumnBorders
      style={{ paddingTop: 0 }}
      verticalSpacing="md"
      noRecordsIcon={true}
      borderRadius="xs"
      withBorder={false}
      columns={[
        {
          accessor: "id",
          title: "#",
          textAlignment: "center",
          width: 50,
          render: ({}) => (
            <Group position="center">
              <Box sx={{ width: 10, height: 10, background: "gray" }}></Box>
            </Group>
          ),
        },

        {
          accessor: "name",
          title: "Fullname",
          render: ({ first_name, last_name }) => {
            return (
              <Text>
                {first_name} {last_name}
              </Text>
            );
          },
        },
        {
          accessor: "phone",
          title: "Phone",
        },
        {
          accessor: "email",
          title: "Email",
        },
        {
          accessor: "account_status",
          title: "Active",
        },
        {
          accessor: "Action",
          title: "Action",
          render: ({ id }) => {
            return (
              <Link to={`${id}`}>
                <Button variant="subtle">View</Button>
              </Link>
            );
          },
        },
      ]}
      records={records}
      totalRecords={users.length}
      recordsPerPage={PAGE_SIZE}
      page={page}
      onPageChange={(p) => setPage(p)}
    />
  );
}
