import {
  Avatar,
  Box,
  Button,
  Divider,
  Group,
  Paper,
  Stack,
  Title,
  Text,
} from "@mantine/core";

import { usePagination } from "../../../hooks/use-pagination";
import { Driver } from "../../../sdk/catalog";
import { DataTable } from "mantine-datatable";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import { useEffect } from "react";
import { adminDriverController } from "../../../config/sdk";

export default observer(function VerificationRequests() {
  let { page, records, setPage, setRecords, PAGE_SIZE } = usePagination<Driver>(
    {
      items: [],
    }
  );

  useEffect(() => {
    adminDriverController.adminDriverControllerGetOutStanding().then((res) => {
      console.log(res.data);
      setRecords(res.data);
    });
  }, []);

  return (
    <Box style={{ overflow: "hidden !important" }} mt="xl">
      <Stack>
        <Paper
          p={"18px"}
          sx={(theme) => {
            return { width: "100%" };
          }}
          shadow="md"
        >
          <Stack>
            <Group position="apart">
              <Box>
                <Title sx={{ fontSize: 24 }}>Verification requests</Title>
              </Box>
            </Group>
            <Divider />
            <DataTable
              striped={false}
              withColumnBorders
              style={{ paddingTop: 0 }}
              verticalSpacing="md"
              noRecordsIcon={true}
              borderRadius="xs"
              withBorder={false}
              columns={[
                {
                  accessor: "id",
                  title: "#",
                  textAlignment: "center",
                  width: 50,
                  render: ({}) => (
                    <Group position="center">
                      <Box
                        sx={{ width: 10, height: 10, background: "gray" }}
                      ></Box>
                    </Group>
                  ),
                },
                {
                  accessor: "_custom",
                  title: "Photo",
                  render: (_) => {
                    return <Avatar src={_.driver_kyc.photo} />;
                  },
                },
                {
                  accessor: "driver_approval_status",
                  title: "Status",
                },
                {
                  accessor: "updated_at",
                  title: "Requested on",
                  render: (_) => {
                    return (
                      <Text>
                        {
                          //@ts-ignore
                          new Date(_.updated_at).toDateString()
                        }
                      </Text>
                    );
                  },
                },
                {
                  accessor: "Action",
                  title: "Action",
                  render: ({ id }) => {
                    return (
                      <Link to={`/drivers/${id}`}>
                        <Button variant="subtle">View</Button>
                      </Link>
                    );
                  },
                },
              ]}
              records={records}
              totalRecords={records.length}
              recordsPerPage={PAGE_SIZE}
              page={page}
              onPageChange={(p) => setPage(p)}
            />
          </Stack>
        </Paper>
      </Stack>
    </Box>
  );
});
