import {
  Box,
  Group,
  Paper,
  SegmentedControl,
  Stack,
  Title,
} from "@mantine/core";
import { payoutManager } from "@store/payout";
import { usersManager } from "@store/users";
import { VerticalKeyValuePair } from "@ui/molecules/text";
import RenderUsers from "@ui/organisms/user-widgets/RenderUsers";
import { ArrowDown2 } from "iconsax-react";
import { DataTable } from "mantine-datatable";
import { observer } from "mobx-react";
import { useEffect } from "react";

export default observer(function DriversScreen() {
  useEffect(() => {
    payoutManager.loadItems();
  }, []);
  return (
    <Box style={{ overflow: "hidden !important" }} mt="xl">
      <Stack>
        <Paper
          p={"18px"}
          sx={(theme) => {
            return { width: "100%" };
          }}
          shadow="md"
        >
          <Stack>
            <Group position="apart">
              <Box>
                <Title sx={{ fontSize: 24 }}>Drivers</Title>
              </Box>
            </Group>
            <Stack
              sx={(theme) => {
                return {};
              }}
            >
              <RenderUsers users={usersManager.drivers} />
            </Stack>
          </Stack>
        </Paper>
      </Stack>
    </Box>
  );
});
