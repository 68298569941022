import { Box, Paper, Tabs } from "@mantine/core";

import DriverTrips from "./DriverTrips";

import { User } from "../../../../sdk/auth";
import UserVerifications from "./UserVerifications";
import UserPayouts from "./UserPayouts";
import UserReviews from "./UserReviews";


export default function ({ user }: { user: User }) {

    return <Box>
        <Paper
            p={"18px"}
            sx={(theme) => {
                return { width: "100%" };
            }}
            shadow="md"
        >
            <Tabs defaultValue="verifications">
                <Tabs.List sx={{ borderBottom: "none" }}>
                    <Tabs.Tab value="verifications">Verifications</Tabs.Tab>
                    <Tabs.Tab value="trips" >Trips</Tabs.Tab>
                    <Tabs.Tab value="payouts" >Payouts</Tabs.Tab>
                    <Tabs.Tab value="reviews">Reviews</Tabs.Tab>
                </Tabs.List>
                <Tabs.Panel value="verifications" pt="xs">
                    <UserVerifications user={user} />
                </Tabs.Panel>

                <Tabs.Panel value="trips" pt="xs">
                    <DriverTrips />
                </Tabs.Panel>

                <Tabs.Panel value="payouts" pt="xs">
                    <UserPayouts />
                </Tabs.Panel>

                <Tabs.Panel value="reviews" pt="xs">
                    <UserReviews />
                </Tabs.Panel>
            </Tabs>

        </Paper>
    </Box>
}