import { Box, Button, Container, Group, JsonInput, Paper, Stack, TextInput, Textarea, Text, Avatar, ScrollArea } from "@mantine/core";
import { useTicketChat } from "../../../hooks/use-ticket";
import { useParams } from "react-router-dom";
import { ticketsManager } from "@store/tickets";
import { useUserHook } from "../../../hooks/user-hook";
import { adminTicketController } from "../../../config/sdk";
import { showNotification } from "@mantine/notifications";
import { AxiosError } from "axios"
import { authManager } from "@store/account/auth";
import { UpdateTicketStatusDtoStatusEnum } from "../../../sdk/catalog";


export default function () {
    let { ticket_id } = useParams()
    let ticket = ticketsManager.items.find(e => e.id == ticket_id);
    let { messages, setMessage, sendMessage, message } = useTicketChat(`ticket-${ticket_id}`);
    let { userProfile } = useUserHook(ticket?.publisher_id ?? "");


    return <Container>
        <Stack my={"12px"}>
            <Paper p="12px">
                <Group position="apart">
                    <Box>
                        <Text>Manage Ticket</Text>
                        <Group>
                            <Avatar src={userProfile?.profile_photo} />
                            <Text>{userProfile?.first_name} {userProfile?.last_name}</Text>
                        </Group>
                    </Box>
                    {ticket?.status == "CLOSED" ? null : <Button onClick={() => {
                        showNotification({ message: 'Action will be completed in the background' })
                        adminTicketController.adminTicketControllerUpdateStatus(ticket_id ?? "", { status: UpdateTicketStatusDtoStatusEnum.Closed }).then(_ => {
                            ticketsManager.loadItems();
                        }).catch(err => {
                            let error = err as AxiosError
                            console.log(error.response);
                        });
                    }}>Close Ticket</Button>}
                </Group>
            </Paper>
            <Paper p={"12px"}>
                <Stack>
                    <ScrollArea sx={{ height: "40vh", overflowY: "auto" }}>
                        <Stack >
                            {messages.map(element => {
                                return <Box sx={{ textAlign: element.user_id == authManager.user.id ? "right" : undefined }}>
                                    <Text >
                                        {element.text}
                                    </Text>
                                    <Text color="dimmed" size={"xs"}>{new Date(element.created_on).toDateString()}</Text>
                                </Box>
                            })}
                        </Stack>
                    </ScrollArea>
                    <Group>
                        <Textarea size="xs" value={message} sx={{ flex: 1 }} onChange={(event) => {
                            setMessage(event.target.value)
                        }} />
                    </Group>
                    <Group position="right">
                        <Button size="xs" onClick={() => {
                            sendMessage()
                        }}>Send</Button>
                    </Group>
                </Stack>
            </Paper>
        </Stack>

    </Container>
}