import { Grid, Group, Avatar, ActionIcon, Box } from "@mantine/core";
import { payoutManager } from "@store/payout";
import { VerticalKeyValuePair } from "@ui/molecules/text";
import { ArrowDown2 } from "iconsax-react";
import { DataTable } from "mantine-datatable";
import { observer } from "mobx-react";
import { Payout, Trip } from "../../../sdk/catalog";
import TripExpanded from "./TripExpanded";
import { usePagination } from "../../../hooks/use-pagination";

export const RenderTrips = observer(({ trips }: { trips: Trip[] }) => {
  let { page, records, setPage, setRecords, PAGE_SIZE } = usePagination<Trip>({
    items: trips,
  });

  return (
    <DataTable
      striped={false}
      withColumnBorders
      style={{ paddingTop: 0 }}
      verticalSpacing="md"
      noRecordsIcon={true}
      borderRadius="xs"
      rowExpansion={{
        allowMultiple: true,
        content: (props) => {
          let data = props.record;
          return <TripExpanded trip={data} />;
        },
      }}
      withBorder={false}
      columns={[
        {
          accessor: "id",
          title: "#",
          textAlignment: "center",
          width: 50,
          render: ({}) => (
            <Group position="center">
              <Box sx={{ width: 10, height: 10, background: "gray" }}></Box>
            </Group>
          ),
        },
        {
          accessor: "start_location.state",
          title: "From",
        },
        {
          accessor: "end_location.state",
          title: "To",
        },
        {
          accessor: "notes",
          title: "Notes",
        },
        {
          accessor: "trip_spec.price_per_seat",
          title: "Price per seat",
        },
        {
          accessor: "*",
          title: "Action",
          width: 100,
          render: ({}) => (
            <Group>
              <ActionIcon>
                <ArrowDown2 variant="Bold" />
              </ActionIcon>
            </Group>
          ),
        },
      ]}
      records={records}
      totalRecords={trips.length}
      recordsPerPage={PAGE_SIZE}
      page={page}
      onPageChange={(p) => setPage(p)}
    />
  );
});
