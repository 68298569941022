import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { DashboardScreen } from "@ui/pages/dashboard";
import { LoginScreen } from "@ui/pages/authentication/LoginScreen";
import { AccountNavigationShell } from "@ui/template/AccountNavigationShell";
import SystemUsersScreen from "@ui/pages/system-users";
import PayoutsScreen from "@ui/pages/payouts";
import { useEffect } from "react";
import { useUtilsLoader } from "../../hooks/loader";
import Drivers from "@ui/pages/drivers";
import Trips from "@ui/pages/trips";
import Bookings from "@ui/pages/bookings";
import UsersScreen from "@ui/pages/users/UsersScreen";
import AccountScreen from "@ui/pages/account/AccountScreen";
import ViewUser from "@ui/pages/user/ViewUser";
import TicketsScreen from "@ui/pages/tickets/TicketsScreen";
import ManageTicket from "@ui/pages/manage-ticket/ManageTicket";
import DriversScreen from "@ui/pages/users/DriversScreen";
import ViewDriver from "@ui/pages/driver/ViewDriver";
import VerificationRequest from "@ui/pages/requests/VerificationRequests";
import VerificationRequests from "@ui/pages/requests/VerificationRequests";

export default function MainAppRoutes() {
  let { load } = useUtilsLoader();

  useEffect(() => {
    load();
  }, []);

  return (
    <Routes>
      <Route path="" element={<AccountNavigationShell />}>
        <Route path="payouts">
          <Route path="" element={<PayoutsScreen />} />
        </Route>

        <Route path="account" element={<AccountScreen />} />

        <Route path="drivers">
          <Route path="" element={<Drivers />} />
        </Route>

        <Route path="trips">
          <Route path="" element={<Trips />} />
        </Route>

        <Route path="tickets">
          <Route path=":ticket_id" element={<ManageTicket />} />
          <Route path="" element={<TicketsScreen />} />
        </Route>

        <Route path="bookings">
          <Route path="" element={<Bookings />} />
        </Route>
        <Route path="users">
          <Route path=":user_id" element={<ViewUser />} />
          <Route path="" element={<UsersScreen />} />
        </Route>
        <Route path="drivers">
          <Route path=":user_id" element={<ViewDriver />} />
          <Route path="" element={<DriversScreen />} />
        </Route>
        <Route path="requests">
          <Route path="" element={<VerificationRequests />} />
        </Route>
        <Route path="staff">
          <Route path="" element={<SystemUsersScreen />} />
        </Route>
        <Route path="" element={<DashboardScreen />} />
        <Route path="*" element={<DashboardScreen />} />
      </Route>

      <Route path="/" element={<div></div>}>
        <Route path="login" element={<LoginScreen />} />
        <Route path="" element={<div />} />
      </Route>
    </Routes>
  );
}
