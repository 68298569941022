import {
  Avatar,
  Box,
  Grid,
  Group,
  Paper,
  Stack,
  Title,
  Text,
  Button,
} from "@mantine/core";
import { payoutManager } from "@store/payout";
import { ticketsManager } from "@store/tickets";
import { VerticalKeyValuePair } from "@ui/molecules/text";
import { RenderPayouts } from "@ui/organisms/payout-widgets/RenderPayouts";
import { observer } from "mobx-react";
import { useEffect } from "react";
import { useUserHook } from "../../../hooks/user-hook";
import { Ticket } from "../../../sdk/catalog";
import { Link } from "react-router-dom";

export default observer(function TicketsScreen() {
  useEffect(() => {
    ticketsManager.loadItems();
  }, []);
  return (
    <Box style={{ overflow: "hidden !important" }} mt="xl">
      <Stack>
        <Paper
          p={"18px"}
          sx={(theme) => {
            return { width: "100%" };
          }}
          shadow="md"
        >
          <Stack>
            <Group position="apart">
              <Box>
                <Title sx={{ fontSize: 24 }}>Tickets</Title>
              </Box>
            </Group>

            <Grid>
              {ticketsManager.items.map((e) => {
                return (
                  <Grid.Col md={6}>
                    <RenderTicketCard ticket={e} />
                  </Grid.Col>
                );
              })}
            </Grid>
          </Stack>
        </Paper>
      </Stack>
    </Box>
  );
});

function RenderTicketCard({ ticket }: { ticket: Ticket }) {
  let { userProfile } = useUserHook(ticket.publisher_id);
  return (
    <Paper p={"12px"} withBorder>
      <Group>
        <Box sx={{ flex: 1 }}>
          <Group position="apart">
            <Group>
              <Avatar src={userProfile?.profile_photo} size={"sm"} />
              <Text size={"sm"} color="dimmed">
                {userProfile?.first_name} {userProfile?.last_name}
              </Text>
            </Group>
            <Text>{ticket.status}</Text>
          </Group>
          <Text mt="12px" sx={{ fontWeight: "bold" }}>
            {
              //@ts-ignore
              ticket.subject ?? "No subject"
            }
          </Text>
          <Text>{ticket.issue}</Text>
          <Group position="right">
            <Link to={`/tickets/${ticket.id}`}>
              <Button variant="subtle" size="sm" onClick={() => {}}>
                View
              </Button>
            </Link>
          </Group>
        </Box>
      </Group>
    </Paper>
  );
}
