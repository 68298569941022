import { Avatar, Box, Grid, Group, Paper, Text } from "@mantine/core";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";

import { useEffect, useState } from "react";
import { Review } from "../../../../sdk/catalog";
import { reviewController } from "../../../../config/sdk";
import { useUserHook } from "../../../../hooks/user-hook";



export default observer(function () {
    let [reviews, setReviews] = useState<Review[]>([]);
    let { user_id } = useParams()

    function load() {
        reviewController.reviewControllerGet(user_id ?? "").then(response => {
            setReviews(response.data)
        }).catch(err => {

        })
    }

    useEffect(() => {
        load();
    }, []);

    return <Grid>
        {reviews.map(review => {
            return <Grid.Col md={6}>
                <Paper withBorder sx={{ padding: "12px" }}>
                    <RenderComment review={review} />
                </Paper>
            </Grid.Col>
        })}
    </Grid>;

});

function RenderComment({ review }: { review: Review }) {
    let { userProfile } = useUserHook(review.user_id)
    return <Group>
        <Avatar src={userProfile?.profile_photo} />
        <Box>
            <Text size={"xs"} color="dimmed">{userProfile?.first_name} {userProfile?.last_name}</Text>
            <Text>{review.comment}</Text>
        </Box>
    </Group>
}