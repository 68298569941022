import { makeAutoObservable, runInAction } from "mobx";
import { adminTripController, bookingController, payoutController } from "../config/sdk";
import { Booking, Payout, Trip } from "../sdk/catalog";

class Manager {
  items: Trip[] = [];
  constructor() {
    makeAutoObservable(this);
  }

  loadItems() {

    adminTripController.adminTripControllerGet().then(payload => {
      runInAction(() => {
        this.items = payload.data
      })
    })
  }
}

export const tripsManager = new Manager();
