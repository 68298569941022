import { MantineTheme, MantineThemeOverride } from "@mantine/core";

export const MaintineThemeConfig: MantineThemeOverride = {
  colorScheme: "dark",
  fontFamily: "'AppRegularFont', sans-serif",
  fontFamilyMonospace: "'AppRegularFont', sans-serif",
  headings: { fontFamily: "'AppRegularFont', sans-serif" },

  components: {
    Button: {
      defaultProps: {},
      styles(theme, params) {
        return {
          root: {
            ":disabled": {
         
            },
            borderRadius: 0,
          },
        };
      },
    },
    Paper: {
      styles(theme, params) {
        return {
          root: {
            borderRadius: 0,
          },
          
        };
      },
    },
    Tabs: {
      defaultProps: {
       
      },
      styles(theme, params) {
        return {
          tab: {
            ":hover": {
              background: "transparent",
              borderColor: "transparent",
            },
          },
        };
      },
    },
    TextInput: {
      defaultProps: {
    
        root:{
          borderRadius: 0,
        }
      },
      styles(theme, params) {
        return {
          input: {
            // background: theme.fn.darken(theme.colors.darkBlue[5], .2),
            borderRadius: 0,
          },
        };
      },
    },
    PasswordInput: {
      defaultProps: {
      
        root:{
          borderRadius: 0,
        }
      },
      styles(theme, params) {
        return {
          input: {
            // background: theme.fn.darken(theme.colors.darkBlue[5], .2),
            borderRadius: 0,
          },
        };
      },
    },

    Modal: {
      styles(theme, params) {
        return {
          modal: {
            // background: theme.colors.darkBlue[5],
            borderColor: "transparent",
            alignSelf: "center",
          },
        };
      },
    },
  },
};
