import {
  Avatar,
  Box,
  Divider,
  Group,
  Input,
  Paper,
  Stack,
  Title,
  Text,
  Grid,
  Center,
  Tabs,
  Button,
} from "@mantine/core";
import { usersManager } from "@store/users";
import { IconPhoto, IconMessageCircle, IconSettings } from "@tabler/icons";
import { HorizontalKeyValuePair } from "@ui/molecules/text";
import { observer } from "mobx-react";
import { useUserHook } from "../../../hooks/user-hook";
import { useParams } from "react-router-dom";
import UserTabs from "./components/UserTabs";

export default observer(function () {
  let { user_id } = useParams();
  let {
    userProfile,
    loadUserProfile,
    toggleProfileStatus,
    makeAdmin,
    removeAdmin,
  } = useUserHook(user_id ?? "");

  return (
    <Box style={{ overflow: "hidden !important" }} mt="xl">
      <Stack>
        <Stack>
          <Group position="apart">
            <Box>
              <Title sx={{ fontSize: 24 }}>View User</Title>
            </Box>
          </Group>
          <Grid align="stretch">
            <Grid.Col md={6}>
              <Paper
                p={"18px"}
                sx={(theme) => {
                  return { width: "100%", height: "100%" };
                }}
                shadow="md"
              >
                <Group position="apart">
                  <Avatar size={"xl"} src={userProfile?.profile_photo} />
                  <Box>
                    {userProfile?.account_status == "ACTIVE" ? (
                      <Button color="red" onClick={toggleProfileStatus}>
                        Deactivate
                      </Button>
                    ) : (
                      <Button color="red" onClick={toggleProfileStatus}>
                        Activate
                      </Button>
                    )}
                    {userProfile?.account_type == "ADMIN" ? (
                      <Button color="green" onClick={removeAdmin}>
                        Remove admin
                      </Button>
                    ) : (
                      <Button color="green" onClick={makeAdmin}>
                        Make admin
                      </Button>
                    )}
                  </Box>
                </Group>
              </Paper>
            </Grid.Col>
            <Grid.Col md={6}>
              <Paper
                p={"18px"}
                sx={(theme) => {
                  return { width: "100%", height: "100%" };
                }}
                shadow="md"
              >
                <Stack>
                  <HorizontalKeyValuePair
                    label="First name"
                    value={userProfile?.first_name ?? ""}
                  />
                  <HorizontalKeyValuePair
                    label="Last name"
                    value={userProfile?.last_name ?? ""}
                  />
                  <HorizontalKeyValuePair
                    label="Account type"
                    value={userProfile?.account_type ?? ""}
                  />
                  <HorizontalKeyValuePair
                    label="Status"
                    value={userProfile?.account_status ?? ""}
                  />
                  <HorizontalKeyValuePair
                    label="Created on"
                    //@ts-ignore
                    value={new Date(userProfile?.created_at).toDateString()}
                  />
                </Stack>
              </Paper>
            </Grid.Col>
          </Grid>
        </Stack>
        <UserTabs />
      </Stack>
    </Box>
  );
});
